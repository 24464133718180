import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, OnChanges } from '@angular/core';
import { DataProviderService } from '../services/data-provider.service';
import { ReservationCart } from '../data/model';
import { UtilitiesService } from '../services/utilities.service';
import { EventManagerService } from '../services/event-manager.service';

@Component({
  selector: 'app-reservation-cart',
  templateUrl: './reservation-cart.component.html',
  styleUrls: ['./reservation-cart.component.css']
})
export class ReservationCartComponent implements OnInit {
  @Input() context;
  reservationCart: ReservationCart;
  displayPricingDetails = false;
  constructor(private dataProvider: DataProviderService,
              private eventManager: EventManagerService,
              public utilities: UtilitiesService) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.reservationCart = this.dataProvider.reservationCart;
  }

  onClickReserveRoom() {
    this.eventManager.viewChange({source: 'reserveRoom', data: this.reservationCart});
  }

  onClickBack() {
    this.eventManager.viewChange({source: 'search-criteria-results'});
  }

  onClickModify() {
    this.eventManager.viewChange({source: 'search-criteria-results'});
    // this.eventManager.viewChange({source: 'modifySearch'});
  }

  onClickCancelledOk() {
    this.displayPricingDetails = false;
  }
}
