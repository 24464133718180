import { Injectable } from '@angular/core';
import { UtilitiesService } from './utilities.service';
import { StorageService } from './storage.service';
import { DataProviderService } from './data-provider.service';

@Injectable({
  providedIn: 'root'
})
export class SearchResultsService {
  constructor(private utilities: UtilitiesService,
              private storageService: StorageService,
              private dataService: DataProviderService) {
  }

  getAvailableRooms(results) {
    const hotelDetails = this.storageService.get(StorageService.hotelDetails);
    const rooms = Array<RoomType>();
    for (const result of results) {
      for (const room of result.room_types_array) {
        // Check if room is already available in room array
        const availableRoom = rooms.find(function(r) {
          return r.RoomId === room.room_id;
        });
        // If room is already present then no need to create room.
        if (availableRoom) {
          // Check if rate plan is present in room or not
          const rateplan = availableRoom.RoomRatePlans.find(function(rateplan) {
            return rateplan.RatePlanId === room.rate_plan_id && rateplan.GuestCount === result.party[0].adults &&
            rateplan.ChildCount === result.party[0].childrenCount && rateplan.InfantCount === result.party[0].infantCount;
          });
          if (!rateplan) { // If rateplan is not available then add the rate plan in room
            this.addRatePlanToRoom(availableRoom, room, result.party[0]);
          }
        } else {
          // If room is not present add that room and rate plan
          const newRoom = new RoomType();
          newRoom.RoomId = room.room_id;
          newRoom.RoomName = room.room_name;
          newRoom.MaxGuests =  this.getMaxGuests(room, hotelDetails); // .room_id,this.dataProvider.hotelDetails.room_types);
          //newRoom.MaxGuestsDisplayArray = Array(newRoom.MaxGuests).fill(newRoom.MaxGuests);
          newRoom.MaxGuestsDisplayArray = this.getMaxGuestsDisplayArray(newRoom.MaxGuests);
          newRoom.RoomDescription =  room.description.split('\n').join('<br>');
          newRoom.RoomDescriptionCard = room.description.substring(0, 150).split('\n').join('<br>');
          if (room.amenities.length > 0) {
            newRoom.Amenities = this.getRoomAmenities(room.amenities);
          } else {
            newRoom.Amenities = this.getRoomAmenities(result.hotel_details.amenities);
          }
          if (room.amenities.length > 0 ) {
            newRoom.AllAmenities = room.amenities;
          } else {
            newRoom.AllAmenities = result.hotel_details.amenities;
          }
          newRoom.Inventory = room.total_room_avail;
          newRoom.Images = new Array<string>();
          newRoom.Images = room.images; // .slice(0,8);
          newRoom.RoomRatePlans = new Array<RoomRatePlan>();
          this.addRatePlanToRoom(newRoom, room, result.party[0]);
          rooms.push(newRoom);
        }
      }
    }

    // Sort rooms and rateplans low to high based on room prices
    for (const room of rooms) {
      room.RoomRatePlans.sort((rateplan1, rateplan2) => {
        return (rateplan1.RatePlanPrice) - (rateplan2.RatePlanPrice);
      });
    }
    return rooms.sort((room1, room2) => {
      return (room1.MinRoomPrice) - (room2.MinRoomPrice);
    });
  }

  addRatePlanToRoom(room, roomData, partyData) {
    const adultCount = partyData.adults;
    const childrenCount = partyData.childrenCount;
    const infantCount = partyData.infantCount;
    const rateplan = new RoomRatePlan();
    rateplan.RatePlanId = roomData.rate_plan_id;
    rateplan.RatePlanName = roomData.rate_plan_name;
    rateplan.MealPlan = this.getMealPlan(roomData.meal_plan_code);
    rateplan.RatePlanPrice = 0;
    rateplan.RatePlanBasePrice = 0;
    rateplan.RatePlanTax = 0;
    for (const item of roomData.line_items) {
      if (item.type === 'price') {
        rateplan.RatePlanPrice += item.price.amount;
        rateplan.RatePlanBasePrice += item.price.amount;
      }
      if (item.type === 'tax') {
        rateplan.RatePlanTax += item.price.amount;
        rateplan.RatePlanTaxDescription = item.description;
      }
    }

    if (roomData.partner_data.PromotionId.length > 0) {
      this.setPromotionToRatePlan(roomData.partner_data.PromotionId, room, rateplan);
    }

    rateplan.InventoryArray = this.getInventoryArray(roomData, rateplan);
    if (roomData.cancellation_policy.id && roomData.cancellation_policy.id.toLowerCase() === 'non-refundable') {
      rateplan.CancellationPolicyTitle = 'NON-REFUNDABLE';
      rateplan.CancellationPolicyDetail = 'This booking is Non-Refundable';
    } else {
      rateplan.CancellationPolicyTitle = 'REFUNDABLE';
      if (roomData.cancellation_policy.text && roomData.cancellation_policy.text !== '') {
        rateplan.CancellationPolicyDetail = roomData.cancellation_policy.text;
      } else {
        rateplan.CancellationPolicyDetail = 'This booking is Refundable';
      }
    }
    rateplan.GuestCount = adultCount;
    //rateplan.GuestCountArray = this.getGuestCountArray();
    //rateplan.GuestCountArray = Array(adultCount).fill(adultCount);
    if (childrenCount > 0) {
      rateplan.ChildCount = childrenCount;
      rateplan.ChildAgeArray = partyData.children;
      rateplan.ChildDisplayArray = Array(childrenCount).fill(childrenCount);
    } else {
      rateplan.ChildAgeArray = new Array<number>();
      rateplan.ChildDisplayArray = new Array<number>();
    }
    if (infantCount > 0) {
      rateplan.InfantCount = infantCount;
      rateplan.ChildAgeArray = partyData.children;
      rateplan.InfantDisplayArray = Array(infantCount).fill(infantCount);
    } else {
      rateplan.InfantDisplayArray = new Array<number>();
    }
    if (room.MinRoomPrice === 0 || room.MinRoomPrice > rateplan.RatePlanPrice) {
      room.MinRoomPrice = rateplan.RatePlanPrice;
      if (rateplan.NonPromotionalPrice) {
        room.MinNonPromotionalPrice = rateplan.NonPromotionalPrice;
        room.DiscountValue = rateplan.Promotion.DiscountValue;
      }
    }
    room.RoomRatePlans.push(rateplan);
  }

  setPromotionToRatePlan(promotionId, room, rateplan) {
    for (const promo of this.dataService.promotions) {
      if (promo.id === promotionId &&
        promo.room_id === room.RoomId &&
        promo.rate_plan_id === rateplan.RatePlanId) {
        const promotion = new Promotion();
        promotion.PromotionId = promo.id;
        promotion.DiscountValue = promo.discount_value;
        promotion.DiscountType = promo.discount_type;
        promotion.MinStay = promo.min_stay;
        promotion.MinAdvancePurchase = promo.min_advance_purchase;
        promotion.MaxAdvancePurchase = promo.max_advance_purchase;
        if (promotion.DiscountType === 'Percent') {
          rateplan.NonPromotionalPrice = (rateplan.RatePlanPrice / ( 100 - promotion.DiscountValue )) * 100;
        }
        rateplan.Promotion = promotion;
        break;
      }
    }
  }

  // Not in use
  confirmBookingStayDates(promo) {
    const today = new Date();
    const todaysDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const bookStartDate = new Date(promo.book_dates.start);
    const bookEndDate = new Date(promo.book_dates.end);
    for (const bookDate = new Date(bookStartDate);
        bookDate.getTime() <= bookEndDate.getTime();
        bookDate.setDate(new Date(bookDate).getDate() + 1)) {
      if (new Date(bookDate).getTime() === todaysDate.getTime()) {
        for (const stayDate of promo.stay_dates) {
          const stayStartDate = new Date(stayDate.start);
          const stayEndDate = new Date(stayDate.end);
          for (const stDate = new Date(stayStartDate);
          stDate.getTime() <= stayEndDate.getTime();
          stDate.setDate(new Date(stDate).getDate() + 1)) {
            if (this.utilities.removeTime(this.dataService.CheckIn).getTime() === new Date(stDate).getTime()) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  getMaxGuests(room, hotelDetails) { // Id,rooms){
    for (const room1 in hotelDetails.room_types) {
      if (hotelDetails.room_types[room1].room_id === room.room_id) {
        return hotelDetails.room_types[room1].max_occupancy;
      }
    }
    return 0;
  }

  getMaxGuestsDisplayArray(count){
    const maxGuestsArray = new Array();
    for (let i = 1; i <= count; i++) {
      maxGuestsArray.push({display:i,value:i});
    }
    return maxGuestsArray;
  }

  getRoomAmenities(amenities) {
    const resultArray = new Array<{}>();
    for (const amenity of amenities) {
      if ((amenity.toLowerCase().includes('air conditioning') || amenity.toLowerCase().includes('ac'))  &&
        resultArray.filter(r => r['name'] === 'Air Conditioning').length === 0) {
        resultArray.push({icon: 'far fa-snowflake', name: 'Air Conditioning'});
      }
      if ((amenity.toLowerCase().includes('swimming') ||
          amenity.toLowerCase().includes('swimming pool') ||
          amenity.toLowerCase().includes('pool')) &&
        resultArray.filter(r => r['name'] === 'Swimming Pool').length === 0) {
        resultArray.push({icon: 'fas fa-swimmer', name: 'Swimming Pool'});
      }
      if ((amenity.toLowerCase().includes('wi-fi') || amenity.toLowerCase().includes('wireless')) &&
        resultArray.filter(r => r['name'] === 'Wi-Fi').length === 0) {
        resultArray.push({icon: 'fas fa-wifi', name: 'Wi-Fi'});
      }
      if ((amenity.toLowerCase().includes('tv') || amenity.toLowerCase().includes('television')) &&
        resultArray.filter(r => r['name'] === 'TV').length === 0) {
        resultArray.push({icon: 'fas fa-tv', name: 'TV'});
      }
      if (amenity.toLowerCase().includes('restaurant') &&
        resultArray.filter(r => r['name'] === 'Restaurant').length === 0) {
        resultArray.push({icon: 'fas fa-utensils', name: 'Restaurant'});
      }
    }

    // Check if resultArray array has length less than 3
    // if(resultArray.length<3) {
    //   // Put some amenities from room amenities
    //   for(let amenity of amenities) {
    //     if(resultArray.indexOf(amenity)<0) {
    //       resultArray.push({name:amenity})
    //     }
    //     if(resultArray.length==3)  break;
    //   }
    // }
    return resultArray;
  }

  getInventoryArray(roomData, rateplan) {
    const inventoryArray = new Array<{}>();
    for (let i = 0; i <= roomData.total_room_avail; i++) {
      inventoryArray.push({name: i.toString(), display: i > 0 ? i.toString() +
        '  (₹' + this.utilities.numberWithCommas(i * rateplan.RatePlanBasePrice) + ')' : '0'});
    }
    return inventoryArray;
  }

  getMealPlan(mp: string) {
    const mealPlan = new MealPlan();
    if (mp === 'EP')  {
      mealPlan.name = 'Room Only';
      mealPlan.icon = 'fas fa-hotel';
      return mealPlan;
    }
    if (mp === 'CP') {
      mealPlan.name = 'Breakfast Included';
      mealPlan.icon = 'fas fa-coffee';
      return mealPlan;
    }
    if (mp === 'MAP') {
      mealPlan.name = 'Breakfast and lunch or dinner';
      mealPlan.icon = 'fas fa-utensils';
      return mealPlan;
    }
    if (mp === 'AP') {
      mealPlan.name = 'All meals included';
      mealPlan.icon = 'fas fa-utensils';
      return mealPlan;
    }
    return mealPlan;
  }
}

export class RoomType {
  RoomId: string;
  RoomName: string;
  MaxGuests: number;
  MaxGuestsDisplayArray: Array<number>;
  RoomRatePlans: Array<RoomRatePlan>;
  Inventory: number;
  RoomCount: number;
  RoomPrice: number;
  MinRoomPrice: number;
  MinNonPromotionalPrice: number;
  RoomDescription: number;
  RoomDescriptionCard: string;
  Amenities: Array<{}>;
  AllAmenities: Array<{}>;
  Images: Array<string>;
  DiscountValue: number;
  constructor() {
    this.RoomCount = 0;
    this.MinRoomPrice = 0;
  }
}
export class RoomRatePlan {
  RatePlanId: string;
  RatePlanName: string;
  GuestCount: number;
  GuestCountArray: Array<number>;
  ChildAgeArray: Array<number>;
  ChildCount: number;
  ChildDisplayArray: Array<number>;
  InfantCount: number;
  InfantDisplayArray: Array<number>;
  RatePlanBasePrice: number;
  RatePlanPrice: number;
  RatePlanTax: number;
  RatePlanTaxDescription: string;
  RatePlanCount: number;
  MealPlan: MealPlan;
  InventoryArray: Array<{}>;
  CancellationPolicyTitle: string;
  CancellationPolicyDetail: string;
  SelectedInventory: {};
  NonPromotionalPrice: number;
  Promotion: Promotion;
  constructor() {
    this.RatePlanCount = 0;
    this.ChildCount = 0;
    this.InfantCount = 0;
  }
}

export class Promotion {
  PromotionId: string;
  DiscountValue: number;
  DiscountType: string;
  MinStay: number;
  MinAdvancePurchase: number;
  MaxAdvancePurchase: number;
}
export class MealPlan {
  name: string;
  icon: string;
}
