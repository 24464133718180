import { Component, OnInit } from '@angular/core';
import { HotelPackage } from '../data/model';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})
export class PackagesComponent implements OnInit {
  packages:HotelPackage[];
  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
    this.getPackages();
  }
  getPackages(){
    this.packages = new Array<HotelPackage>();
    let hotelpackage = new HotelPackage();
    hotelpackage.Title = "Spacation";
    hotelpackage.Description = "The Rate plan includes : Complimentary Buffet Breakfast. Free Swimming Pool Access. Complimentary Access to Health Club. Complimentary 4 Water Bottles per day. Daily Newspaper. High Speed Wifi Internet";
    hotelpackage.Price=4000;
    hotelpackage.ImageURL="https://www.franchiseindia.com/uploads/content/wi/art/5af56ee7627dd.jpg";
    this.packages.push(hotelpackage);

    let hotelpackage2 = new HotelPackage();
    hotelpackage2.Title = "Family Getaway Summer offer 3 nights";
    hotelpackage2.Description = "Discover an idyllic embodiment of the spirit of Kerala’s renowned backwaters at the Taj Bekal Resort & Spa. Escape the summer heat in your distinctive villa, inspired by the contours of a traditional houseboat, or lounge in the cool green expanses of our beautiful gardens and sink your feet into the backwaters meandering past. ";
    hotelpackage2.Price=11000;
    hotelpackage2.ImageURL="https://d3ba08y2c5j5cf.cloudfront.net/wp-content/uploads/2017/10/07132105/12-week-holiday-getaway-contest_Hero-e1507881925361.png";
    this.packages.push(hotelpackage2);
  }
}
