import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private datepipe:DatePipe) { }

  public dateDifference(startDate, endDate) {
    let oneDay = 1000 * 60 * 60 * 24;
    return Math.round((endDate.getTime() - startDate.getTime()) / oneDay);
  }

  public getMonthName(monthNumber,type?){
    let monthsLong = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
    let monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    if(type && type == "short"){
        return monthsShort[monthNumber];
    }
    return monthsLong[monthNumber];
  }

  public getDayName(dayNumber,type?){
    var daysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    if(type == "short") return daysShort[dayNumber]
    return daysLong[dayNumber];
  }

  public numberWithCommas(x) {
    x = x.toFixed(0);
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  transformDate(date,dateformat) {
    return this.datepipe.transform(date, dateformat); 
  }

  getActiveDates(month,year){
    const activeDates = [];
    const today = new Date();
    // Calculate maxdate of the month
    const maxDate = new Date(year, month, 0).getDate();
    // Check if month is current month
    if (today.getMonth() === month && today.getFullYear() === year) {
      // add today and future dates to the activeDates array
      for (let date = today.getDate(); date <= maxDate; date++) {
        activeDates.push(date.toString());
      }
    } else {
      for (let date = 1;date <= maxDate; date++) {
        activeDates.push(date.toString());
      }
    }
    return activeDates;
  }

  getDialogDates(checkinDate,disabledDates){
    let dialogDates = [];
    let chkinDate = new Date(checkinDate);
    let disabledDate = false;
    for(let i = 0;i<15;i++){
      if(disabledDate){
        if(i!=0){
          dialogDates.push({date:new Date(chkinDate),displayDate:this.getDisplayDate(new Date(chkinDate),checkinDate),disabled:disabledDate });
        }  
      }else{
        if(i!=0){
          dialogDates.push({date:new Date(chkinDate),displayDate:this.getDisplayDate(new Date(chkinDate),checkinDate),disabled:disabledDate });
          disabledDate = this.isDisabled(chkinDate,disabledDates);
        }  
      }
      chkinDate.setDate(chkinDate.getDate() + 1);  
    }
    return dialogDates;
  }

  getDisplayDate(date,checkinDate){
    let date1 = new Date(date);
    let daysDiff = this.dateDifference(checkinDate,date);
    return {date:date1,daysDiff:daysDiff};
  }

  isDisabled(checkindate,disabledDates){
    for(let date of disabledDates){
      if(date.getTime() == checkindate.getTime()){
        return true;
      }
    }
    return false;
  }
  base64toString(base64){
   return this.bytesToString(this.base64ToBytes(base64));
  }
  // Convert a base-64 string to a byte array
  base64ToBytes(base64) {
    var base64map = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/',
    // Remove non-base-64 characters
    base64 = base64.replace(/[^A-Z0-9+\/]/ig, '');

    for (var bytes = [], i = 0, imod4 = 0; i < base64.length;
        imod4 = ++i % 4) {
        if (imod4 == 0) continue;
        bytes.push(((base64map.indexOf(base64.charAt(i - 1))
            & (Math.pow(2, -2 * imod4 + 8) - 1)) << (imod4 * 2))
            | (base64map.indexOf(base64.charAt(i)) >>> (6 - imod4 * 2)));
    }
    return bytes;
    }

    // Convert a byte array to a string
    bytesToString(bytes) {
      for (var str = [], i = 0; i < bytes.length; i++)
        str.push(String.fromCharCode(bytes[i]));
      return str.join('');
    }

    removeTime(date) {
      let d1 = new Date(date);
      d1.setHours(0);
      d1.setMinutes(0);
      d1.setSeconds(0);
      d1.setMilliseconds(0);
      return d1;
    }

    getDiscountPercentage(actualValue, discountedValue) {
      const discountPercentage = (( actualValue - discountedValue ) / actualValue) * 100;
      return Math.round(discountPercentage * 100) / 100;
    }
}
