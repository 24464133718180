import { Component, OnInit } from '@angular/core';
import { ReservationCart } from '../data/model';
import { DataProviderService } from '../services/data-provider.service';

@Component({
  selector: 'app-t2-guest-details',
  templateUrl: './t2-guest-details.component.html',
  styleUrls: ['./t2-guest-details.component.css']
})
export class T2GuestDetailsComponent implements OnInit {
  reservationCart:ReservationCart;
  constructor(private dataProvider:DataProviderService) { }

  ngOnInit() {
    window.scroll(0,0);
    this.reservationCart = this.dataProvider.reservationCart;
  }

}
