export interface HotelDetailsRS {
    result_list: HotelInfo[]
}
  

export interface HotelInfo {
    hotel_id: string
    name: string
    street: string
    city: string
    partner_Id: string
    postal_code: string
    state: string
    country: string
    latitude: number
    longitude: number
    desc: string
    checkinout_policy: string
    image: string[]
    star_rating: string
    phone: string
    room_types: any
    amenities: string[]
    url: string
    websiteurl: string
    email: string
    InfantAgeLimit: number
    ChildAgeLimit: number
    PaymentGateway: string
    house_rules: string
}
  
export class AddOns {
    Title: string;
    Description: string;
    Price: number;
    Tax: number;
}
export class HotelPackage {
    Title: string;
    Description: string;
    Price: number;
    ImageURL: string;
}
export class SearchCriteria {
    stayDateStart: string;
    stayDateEnd: string;
    checkinDate: Date;
    checkoutDate: Date;
    adultsCount: number;
    kidsCount: number;
    roomNights: number;
    rooms: RoomCriteria[];
    promoCode: string;
    rateCode: string;
}
export class RoomCriteria {
    RoomNumber: number;
    AdultsCount: number;
    DisableAddAdultButton: boolean;
    DisableRemoveAdultButton: boolean;
    KidsAgeArray: Array<number>;
    DisableAddKidButton: boolean;
    DisableRemoveKidButton: boolean;
}
export class SelectedCriteria {
    RoomsArray: Array<SelectedRoom>;
    AdultsCount: number;
    SearchCriteria: SearchCriteria;
    constructor() {
        this.RoomsArray = new Array<SelectedRoom>();
        // this.SearchCriteria = new SearchCriteria();
    }
}
export class SelectedRoom {
    RoomId: string;
    RoomName: string;
    RoomPrice: number;
    SelectedRatePlan: Array<SelectedRatePlan>;
    constructor()  {
        this.RoomPrice = 0;
    }
}
export class SelectedRatePlan {
    RatePlanId: string;
    RatePlanCount: number;
    RatePlanName: string;
    GuestCount: number;
    ChildCount: number;
    InfantCount: number;
    ChildAgeArray: Array<number>;
    RatePlanPrice: number;
    NonPromotionalPrice: number;
    RatePlanTax: number;
    RatePlanTaxDescription: string;
    MealPlan: string;
    PromotionId: string;
    constructor() {
        this.RatePlanCount = 0;
        this.RatePlanPrice = 0;
        this.NonPromotionalPrice = 0;
        this.RatePlanTax = 0;
        this.ChildCount = 0;
        this.InfantCount = 0;
    }
}
export class ReservationCart {
    BookingId: string;
    CheckinDate: Date;
    CheckoutDate: Date;
    RoomNights: number;
    NumberOfAdults: number;
    NumberOfKids: number;
    ReservationRooms: Array<ReservationRoom>;
    TotalTaxAmount: number;
    TotalAmount: number;
    TotalNonPromotionalAmount: number;
    constructor() {
        this.NumberOfAdults = 0;
        this.TotalAmount = 0;
        this.TotalTaxAmount = 0;
        this.TotalNonPromotionalAmount = 0;
        this.NumberOfKids = 0;
    }
}
export class ReservationRoom {
    RoomName: string;
    RatePlanName: string;
    MealPlan: string;
    RoomPrice: number;
    RoomTax: number;
    RoomPriceNonPromoional: number;
    RoomTaxDescription: string;
    AdultsCount: number;
    ChildCount: number;
    InfantCount: number;
    ChildAgeArray: Array<number>;
}

export class TransactionDetails {
    TransactionID: string;
    ApTransactionID: string;
    Status: string;
    Amount: number;
    Message: string;
    MerchantID: string;
    Username: string;
}

export class BookingVoucher {
    HotelID: string;
    BookingID: string;
    BookingStatus: string;
    CheckinDate: Date;
    CheckoutDate: Date;
    Customer: any;
    Hotel: any;
    Rooms: Array<VoucherRoom>;
    AmountExcludingTax: number;
    TotalTax: number;
    PaymentType: string;
    constructor() {
        this.AmountExcludingTax = 0;
        this.TotalTax = 0;
    }
}

export class VoucherRoom {
    RoomName: string;
    RoomDescription: string;
    RatePlanName: string;
    AdultsCount: number;
    KidsCount: number;
    Amount: number;
    MealPlan: any;
    constructor() {
        this.AdultsCount = 0;
        this.KidsCount = 0;
    }
}
