import { Injectable } from '@angular/core';

declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  sendEvent(category, action, label){
    gtag('event',
          action,
          {
            event_category : category,
            event_label : label,
            hotelid : 123
          });
  }
}
