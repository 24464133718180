import { Component, OnInit } from '@angular/core';
import { AddOns } from '../data/model';

@Component({
  selector: 'app-add-ons',
  templateUrl: './add-ons.component.html',
  styleUrls: ['./add-ons.component.css']
})
export class AddOnsComponent implements OnInit {
  services:AddOns[];
  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
    this.getAddOns();
  }
  getAddOns(){
    this.services = new Array<AddOns>();
    for(let i=0;i<3;i++){
      let service = new AddOns();
      service.Title = "Chennai airport to Villa Shanti - 4 persons max";
      service.Description = "Transfer in Toyota Innova for a maximum of 4 persons Duration : 2-3 Hours";
      service.Price=4000;
      service.Tax=1000;
      this.services.push(service);
    }
  }
}
