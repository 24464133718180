import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { DataProviderService } from './services/data-provider.service';
import { LoadingService } from './services/loading.service';
import { ThemeService } from './services/theme.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { StorageService } from './services/storage.service';
import { UtilitiesService } from './services/utilities.service';
import { environment } from '../environments/environment';
import { themes } from './data/theme';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EventManagerService } from './services/event-manager.service';
import { Title } from '@angular/platform-browser';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HotelInfo } from './data/model';

@Component({
  selector: 'app-booking-engine',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  displayDialog = false;
  dialogHeader = '';
  dialogMessage = '';
  displayHeader = false;
  transactionDetails;
  displaySearchResults = false;
  displayAdditionalServices = false;
  displayGuestDetails = false;
  displaySearchCriteria = false;
  displayPackages = false;
  displayBookingVoucher = false;
  displayErrorPage = false;
  displayManageBooking = false;
  displayNoResults = false;
  bookingId;
  emailId;
  displayState = '1';
  context;
  errormsg = '';
  reservationData;
  res;
  themes = [];
  color;
  hasInternetConnectivity = true;
  environment;
  hotelBg;
  numRequest = 0;
  hotelDetails : HotelInfo;
  hotelLogo;
  dialogSubscription;
  viewChangeSubscription;
  searchResultsSubscription;
  @ViewChild('searchResults') public searchResults: ElementRef;
  constructor(
              public dataService: DataProviderService,
              public eventManager: EventManagerService,
              private elementRef: ElementRef,
              public loadingService: LoadingService,
              private themeService: ThemeService,
              private storageService: StorageService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private utilities: UtilitiesService,
              @Inject(DOCUMENT) private document: Document,
              private title: Title,
              private sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    window.scroll(0, 0);
    this.environment = environment;
    this.unsubscribeEvents();
    this.dataService.getThemeData().subscribe(res => {
      this.themeService.currentThemes = res;
      AppComponent.loadGoogleAnalytics('G-JS2ZE0RN3Y');
      this.appStart();
    });
  }

  appStart() {
    this.subscribetoDisplayDialog();
    this.subscribeToViewChangeListener();
    this.route.queryParams.subscribe(params => {
      this.numRequest++;
      let hid = params.hid ? params.hid : "IN313217";
      if (params.res) {// This condition will be true in case of callback from payment gateway
        this.checkPaymentStatus(params);
      } else if (hid) {
        this.initApp(params);
      } else {
        if (this.numRequest >= 2) {
          this.eventManager.displayDialog({
            header: 'Incorrect URL',
            detail: 'Please enter correct url!'
          });
        }
      }
    });
  }

  ngAfterViewInit() {
    this.scrollToResults();
  }

  getHotelDetails(switchTo?) {
      this.dataService.domainHotels = new Array();
      const hotelDetails : HotelInfo = this.storageService.get(StorageService.hotelDetails);
      if (hotelDetails && (hotelDetails.hotel_id.toLowerCase() === this.dataService.hotelId.toLowerCase())) { // Use cached hotel details
        if (this.dataService.domainId || !this.dataService.isEnvironmentPartnerCode) {
          this.dataService.domainHotels = this.storageService.get(StorageService.domainHotels);
        }
        this.hotelDetails = hotelDetails;
        this.displayHeader = true;
        if (switchTo !== undefined) {
          this.switchToView(switchTo);
        } else {
          return;
        }
      } else {
        this.loadingService.showLoading = true;
        this.getHotelDetailsFromServer(switchTo);
      }
  }

  initApp(params: Params) {
    this.setInitialAttributes(params); // set query params we get from url to variables in data service
    this.hotelBg = this.sanitizer.bypassSecurityTrustStyle(
      `url(${environment.images_url + this.dataService.hotelId.toLowerCase() + '/bg.png'})`);
    this.themeService.setActiveTheme(this.dataService.hotelId,
                                    this.dataService.domainId,
                                    this.dataService.partnerCode); // sets theme of hotel
    this.storageService.set(StorageService.params, params);
    if (params.view && params.view === 'voucher') {
      this.getHotelDetails('manage-booking');
    } else {
      this.getHotelDetails('search-criteria-results');
    }
  }

  checkPaymentStatus(params) {
    const transactionDetails = this.dataService.setTransationDetails(this.utilities.base64toString(params.res).split(':'));
    this.restoreDataVariables();
    this.themeService.setActiveTheme(this.dataService.hotelId,
      this.dataService.domainId,
      this.dataService.partnerCode); // sets theme of hotel

    if (transactionDetails.TransactionStatus === '200') {
      this.displaySearchCriteria = false;
      this.displaySearchResults = false;
      this.hotelBg = this.sanitizer.bypassSecurityTrustStyle(
        `url(${environment.images_url + this.dataService.hotelId.toLowerCase() + '/bg.png'})`
        );
      this.getHotelDetails('display-booking-voucher');
      this.googleAnalyticsService.sendEvent(this.hotelDetails.name, 'Current Page', 'Booking Confirmed');
    } else {
      this.eventManager.displayDialog({
        header: 'Booking Failed',
        detail: 'Booking failed due to transaction failure. Please retry using different payment option.'
      });
      this.getHotelDetails();
      this.googleAnalyticsService.sendEvent(this.hotelDetails.name, 'Current Page', 'Booking Cancelled');
      this.dataService.routeToHomePage(this.dataService.hotelId);
    }
    this.storageService.set(StorageService.params, params);
  }

  restoreDataVariables() {
    this.dataService.hotelId = this.storageService.get(StorageService.hotelid);
    this.dataService.domainId = this.storageService.get(StorageService.domainId);
    this.dataService.partnerCode = this.storageService.get(StorageService.partnercode);
    this.dataService.CheckIn = this.storageService.get(StorageService.checkin);
    this.dataService.CheckOut = this.storageService.get(StorageService.checkout);
    this.dataService.isEnvironmentPartnerCode = this.storageService.get(StorageService.isEnvPartnerCode);
  }

  changeView(viewInfo) {
    switch (viewInfo.source) {
      case 'switch-hotel':    this.displayHeader = false;
                              this.displayBookingVoucher = false;
                              this.displaySearchCriteria = false;
                              this.displaySearchResults = false;
                              this.displayAdditionalServices = false;
                              this.displayGuestDetails = false;
                              this.displayPackages = false;
                              this.displayErrorPage = false;
                              this.displayManageBooking = false;
                              this.displayNoResults = false;
                              break;
      case 'manage-booking':  this.googleAnalyticsService.sendEvent(this.hotelDetails.name, 'Current Page', 'Manage Booking');
                              this.displayHeader = true;
                              this.displayBookingVoucher = false;
                              this.displaySearchCriteria = false;
                              this.displaySearchResults = false;
                              this.displayAdditionalServices = false;
                              this.displayGuestDetails = false;
                              this.displayPackages = false;
                              this.displayErrorPage = false;
                              this.displayManageBooking = true;
                              this.displayNoResults = false;
                              break;
      case 'display-booking-voucher': this.emailId = viewInfo.data.emailId;
                                      this.bookingId = viewInfo.data.bookingId;
                                      this.displayHeader = true;
                                      this.displayBookingVoucher = true;
                                      this.displaySearchCriteria = false;
                                      this.displaySearchResults = false;
                                      this.displayAdditionalServices = false;
                                      this.displayGuestDetails = false;
                                      this.displayPackages = false;
                                      this.displayErrorPage = false;
                                      this.displayManageBooking = false;
                                      this.displayNoResults = false;
                                      break;
        case 'searchResults': this.context = 'guestDetails';
                              this.googleAnalyticsService.sendEvent(this.hotelDetails.name, 'Current Page', 'Guest Details');
                              this.displaySearchCriteria = false;
                              this.displaySearchResults = false;
                              this.displayGuestDetails = true;
                              this.displayPackages = false;
                              this.displayBookingVoucher = false;
                              this.displayManageBooking = false;
                              this.displayNoResults = false;
                              break;
        // For time being this is commented as we are not considering Add-ons now.
        // case 'reserveRoom':   this.context = 'guestDetails';
        //                       this.displaySearchCriteria = false;
        //                       this.displaySearchResults = false;
        //                       this.displayAdditionalServices = false;
        //                       this.displayGuestDetails = true;
        //                       this.displayPackages = false;
        //                       break;
        case 'search-criteria-results': this.googleAnalyticsService.sendEvent(this.hotelDetails.name, 'Current Page', 'Search Results');
                                        this.displaySearchCriteria = true;
                                        this.displaySearchResults = true;
                                        this.displayAdditionalServices = false;
                                        this.displayGuestDetails = false;
                                        this.displayPackages = false;
                                        this.displayBookingVoucher = false;
                                        this.displayErrorPage = false;
                                        this.displayManageBooking = false;
                                        this.displayNoResults = false;
                                        break;
        case 'no-results-found':    this.googleAnalyticsService.sendEvent(this.hotelDetails.name, 'Current Page', 'No Results Found');
                                    this.displaySearchCriteria = true;
                                    this.displaySearchResults = false;
                                    this.displayAdditionalServices = false;
                                    this.displayGuestDetails = false;
                                    this.displayPackages = false;
                                    this.displayBookingVoucher = false;
                                    this.displayErrorPage = false;
                                    this.displayManageBooking = false;
                                    this.displayNoResults = true;
                                    break;
        case 'guestDetails':        this.googleAnalyticsService.sendEvent(this.hotelDetails.name, 'Current Page', 'Payment');
                                    this.displayHeader = true;
                                    this.displayBookingVoucher = true;
                                    this.displaySearchCriteria = false;
                                    this.displaySearchResults = false;
                                    this.displayAdditionalServices = false;
                                    this.displayGuestDetails = false;
                                    this.displayPackages = false;
                                    this.displayErrorPage = false;
                                    this.displayManageBooking = false;
                                    this.displayNoResults = false;
                                    break;
        case 'modifySearch':        this.googleAnalyticsService.sendEvent(this.hotelDetails.name, 'Current Page', 'Search Criteria');
                                    this.displaySearchResults = false;
                                    this.displayAdditionalServices = false;
                                    this.displayGuestDetails = false;
                                    this.displayPackages = false;
                                    this.displayBookingVoucher = false;
                                    this.displaySearchCriteria = true;
                                    this.displayPackages = true;
                                    this.displayErrorPage = false;
                                    this.displayManageBooking = false;
                                    this.displayNoResults = false;
                                    break;
        case 'error-page':          this.googleAnalyticsService.sendEvent(this.hotelDetails.name, 'Current Page', 'Error Page');
                                    this.displaySearchResults = false;
                                    this.displayAdditionalServices = false;
                                    this.displayGuestDetails = false;
                                    this.displaySearchCriteria = false;
                                    this.displayPackages = false;
                                    this.displayBookingVoucher = false;
                                    this.displayErrorPage = true;
                                    this.displayManageBooking = false;
                                    this.displayNoResults = false;
                                    break;
    }
    this.cdr.detectChanges();
  }

  getHotelDetailsFromServer(switchTo) {
    this.dataService.getHotelDetails().subscribe(result => {
      // If domain id is present in the url then set the list of domain hotels
      // in order to render the dropdown in search criteria
      if (this.dataService.domainId || !this.dataService.isEnvironmentPartnerCode) {
        this.dataService.domainHotels = result.result_list;
        this.storageService.set(StorageService.domainHotels, result.result_list);
      }
      // This wiil set the hotel details to the selected hotel
      for (const res of result.result_list) {
        if (res.hotel_id.toLowerCase() === this.dataService.hotelId.toLowerCase()) {
          this.hotelDetails = res;
          this.storageService.set(StorageService.hotelDetails, res);
        }
      }
      if (this.hotelDetails) {
        this.displayHeader = true;
        this.title.setTitle(this.hotelDetails.name);
        let favIconUrl = environment.images_url + this.hotelDetails.hotel_id.toLowerCase() + '/logo.png';
        document.getElementById('appFavicon').setAttribute('href', favIconUrl);

        this.loadingService.showLoading = false;
        if (switchTo !== undefined) {
          this.switchToView(switchTo);
        } else {
          return;
        }
      } else {
        this.loadingService.showLoading = false;
        this.eventManager.displayDialog({
          header: 'Booking Engine Not Configured',
          detail: 'Booking engine seems to be not configured. Please ask support team to configure booking engine for the hotel.'
        });
      }
    },
    error => {
      // Connectivity issue
      this.eventManager.viewChange({source: 'error-page'});
      this.loadingService.showLoading = false;
    });
  }
  switchToView(switchTo) {
    if (switchTo === 'display-booking-voucher') {
      this.showBookingVoucher();
    } else {
      this.eventManager.viewChange({source: switchTo});
    }
    this.cdr.detectChanges();
  }

  getReservationCartContext() {
    if (this.displayAdditionalServices) {
      return 'add-ons';
    }
    if (this.displayGuestDetails) {
      return 'guest-details';
    }
  }

  showBookingVoucher() {
    const guestDetails = this.storageService.get(StorageService.guestDetails);
    this.eventManager.viewChange(
      {
        source: 'display-booking-voucher',
        data: {
          bookingId: 'MxM' + this.dataService.transactionDetails.TransactionID,
          emailId: guestDetails.email
      }});
  }
  setInitialAttributes(params: Params) {
    // Get parameters from url for app (non-widget app)
    let hotelId = params.hid ? params.hid : "IN313217";

    if (environment.widget === false && hotelId) {
      this.dataService.hotelId = hotelId;
      this.storageService.set(StorageService.hotelid, this.dataService.hotelId);
      if (params.roomcode){
        this.dataService.RoomCode = params.roomcode;
      }
      if (params.ratecode){
        this.dataService.RateCode = params.ratecode;
      }
      if (params.checkin && params.checkout) {
        this.dataService.CheckIn = params.checkin;
        this.dataService.CheckOut = params.checkout;
      } else {
        const todaysDate = new Date();
        const tommorowsDate = new Date();
        tommorowsDate.setDate(todaysDate.getDate() + 1);
        this.dataService.CheckIn = this.utilities.transformDate(todaysDate, 'yyyy-MM-dd')
        this.dataService.CheckOut = this.utilities.transformDate(tommorowsDate, 'yyyy-MM-dd')
      }
      this.storageService.set(StorageService.checkin, this.dataService.CheckIn);
      this.storageService.set(StorageService.checkout, this.dataService.CheckOut);
      if(params.nRooms) {
        this.dataService.nRooms = Number(params.nRooms);
      }
      if(params.nAdults) {
        this.dataService.nAdults = Number(params.nAdults);
      }
      if(params.nChildrens) {
        this.dataService.nChildrens = Number(params.nChildrens);
      }
      if(params.nChildAgeArray) {
        this.dataService.childAgeArray = params.nChildAgeArray;
      }
      if (params.did) {
        this.storageService.set(StorageService.domainId, params.did);
        this.dataService.domainId = params.did;
      }
      if (params.promocode) { this.dataService.promoCode = params.promocode; }
      if (params.partnercode) {
        this.dataService.partnerCode = params.partnercode;
        this.dataService.isEnvironmentPartnerCode = false;
      } else {
        this.dataService.partnerCode = environment.partnerCode;
        this.dataService.isEnvironmentPartnerCode = true;
      }
      this.storageService.set(StorageService.partnercode, this.dataService.partnerCode);
      this.storageService.set(StorageService.isEnvPartnerCode, this.dataService.isEnvironmentPartnerCode);
      this.storageService.set(StorageService.initialparams, params);
      this.dataService.CallbackURL = environment.payment_callback_url;
    }

    // Widget code
    if (environment.widget === true) {
      this.dataService.hotelId = this.elementRef.nativeElement.getAttribute('HotelId');
      this.dataService.Lang = this.elementRef.nativeElement.getAttribute('Lang');
      this.dataService.Currency = this.elementRef.nativeElement.getAttribute('Currency');
      this.dataService.setAdults(parseInt(this.elementRef.nativeElement.getAttribute('Adults')));
      this.dataService.setChildren(parseInt(this.elementRef.nativeElement.getAttribute('Children')));
      this.dataService.setCheckIn(this.elementRef.nativeElement.getAttribute('CheckIn'));
      this.dataService.setCheckOut(this.elementRef.nativeElement.getAttribute('CheckOut'));
      this.dataService.promoCode = this.elementRef.nativeElement.getAttribute('CouponCode');
      this.dataService.CallbackURL = this.elementRef.nativeElement.getAttribute('CallbackURL');
    }
  }

  onClickCancelledOk() {
    this.displayDialog = false;
  }

  updateBooking(transactionDetails) {
    const payload = {
      booking_id: 'MxM' + transactionDetails.TransactionID,
      Tags: {
        TransactionId:  transactionDetails.ApTransactionID,
        TransactionStatus: transactionDetails.Status,
        TransactionAmount: transactionDetails.Amount,
        TransactionResponse: transactionDetails.Message,
        TransactionTime: new Date()
      }
    };
    this.loadingService.showLoading = true;
    this.dataService.updateBooking(payload).subscribe(
      result => {
        this.loadingService.showLoading = false;
        if (result.status === 'Success') {
          console.log('Booking updated successfully');
          this.getHotelDetails('display-booking-voucher');
        } else {
          this.eventManager.viewChange({source: 'error-page'});
        }
    },
    error => {
      // Connectivity issue
      this.loadingService.showLoading = false;
      this.eventManager.viewChange({source: 'error-page'});
      });
  }

  onChangeTemplate(template) {
    this.dataService.template = template;
  }

  getThemes() {
    for (const t of themes) {
      this.themes.push({label: t.themename, value: t.themename});
    }
  }

  onThemeChange(event) {
    // this.themeService.setActiveTheme(event.value);
  }

  scrollToResults() {
    // This method will scroll to results after search in case of mobile and tablets
    const that = this;
    this.searchResultsSubscription = this.eventManager.searchResultListener$.subscribe(() => {
      setTimeout(() => {
        that.searchResults.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start'});
      }, 1000);
    });
  }

  subscribetoDisplayDialog() {
   this.dialogSubscription = this.eventManager.dialogListener$.subscribe((dialogmsg) => {
       this.dialogHeader = dialogmsg.header;
      this.dialogMessage = dialogmsg.detail;
      this.displayDialog = true;
      this.cdr.detectChanges();
    });
  }

  subscribeToViewChangeListener() {
    this.viewChangeSubscription =  this.eventManager.viewChangeListener$.subscribe(
      viewInfo => {
        const that = this;
        this.loadingService.showLoading = true;
        setTimeout(() => {
          that.changeView(viewInfo);
          that.loadingService.showLoading = false;
          }, 1000);
      });
  }

  getDialogSize() {
    if (window.innerWidth < 575) {
      return {
        width: window.innerWidth * 0.8 + 'px',
        height: window.innerHeight * 0.3 + 'px',
        'min-height': '140px',
        'min-width': '200px'
      };
    } else {
      return {
        width: window.innerWidth * 0.4 + 'px',
        height: window.innerHeight * 0.2 + 'px',
        'min-height': '140px',
        'min-width': '200px'
      };
    }
  }

  unsubscribeEvents() {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
    if (this.viewChangeSubscription) {
      this.viewChangeSubscription.unsubscribe();
    }
    if (this.searchResultsSubscription) {
      this.searchResultsSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribeEvents();
  }

  // https://stackoverflow.com/questions/55100379/angular-dynamically-loading-google-analytics
  static loadGoogleAnalytics(trackingID: string): void {

    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);

    /**
     *     window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-20452855-2', { 'send_page_view': false,'custom_map': {'dimension1': 'hotelid' }});
      gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;
     */
    let gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', \'${ trackingID }\', { 'send_page_view': false, 'custom_map': {'dimension1': 'hotelid' }});`;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }
}
