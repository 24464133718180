import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import * as jQuery from 'jquery';
import { EventManagerService } from '../services/event-manager.service';
@Component({
  selector: 'app-manage-booking',
  templateUrl: './manage-booking.component.html',
  styleUrls: ['./manage-booking.component.css']
})
export class ManageBookingComponent implements OnInit {

  constructor(private eventManager: EventManagerService) { }

  email;
  bookingid;
  ngOnInit() {
    this.setHeight();
  }

  onFormSubmit(form) {
    console.log(form.value);
    this.email = form.value.email;
    this.bookingid = form.value.bookingid;

    this.eventManager.viewChange(
      {
        source: 'display-booking-voucher',
        data: {
          bookingId: form.value.bookingid,
          emailId: form.value.email
      }});
  }

  @HostListener('window:resize') setHeight() {
    const windowHeight = window.innerHeight;
    jQuery('.manage-booking-main').css('height', windowHeight);
  }
}
