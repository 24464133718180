import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SafePipe } from './safeurl.pipe';

// Components (Built in this app)
import { AppComponent } from './app.component';
import { SearchCriteriaComponent } from './search-criteria/search-criteria.component';
import { AddOnsComponent } from './add-ons/add-ons.component';
import { GuestDetailsComponent } from './guest-details/guest-details.component';
import { ReservationCartComponent } from './reservation-cart/reservation-cart.component';
import { PackagesComponent } from './packages/packages.component';
import { BookingVoucherComponent } from './booking-voucher/booking-voucher.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { T2GuestDetailsComponent } from './t2-guest-details/t2-guest-details.component';
import { CheckOutDropdownComponent } from './search-criteria/search-criteria.component';
import { HeaderInfoDialog } from './header/header.component';

// Other Components
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlModule } from 'ngx-owl-carousel';
import {ConnectionServiceModule, ConnectionServiceOptions, ConnectionServiceOptionsToken} from 'ng-connection-service';

// Primeng Components
import {ToastModule} from 'primeng/toast';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InputTextModule} from 'primeng/inputtext';
import {DialogModule} from 'primeng/dialog';
import { MessageService } from 'primeng/components/common/messageservice';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {BlockUIModule} from 'primeng/blockui';

// Material imports
import {
  MatButtonModule,
  MatCardModule,
  MatTabsModule,
  MatCheckboxModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatSnackBarModule,
  MatFormFieldModule,
  MatDialogModule,
  MatTooltipModule,
  MatRadioModule
} from '@angular/material';
import { ConnectionService } from 'ng-connection-service';
import { HeaderComponent } from './header/header.component';
import { MarkdownModule } from 'ngx-markdown';

import { Routes, RouterModule } from '@angular/router';
import { ManageBookingComponent } from './manage-booking/manage-booking.component';
import { FooterComponent } from './footer/footer.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { NoResultsComponent } from './no-results/no-results.component';
const appRoutes: Routes = [
  {path: '**', component: AppComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    SearchCriteriaComponent,
    AddOnsComponent,
    GuestDetailsComponent,
    ReservationCartComponent,
    PackagesComponent,
    BookingVoucherComponent,
    SearchResultsComponent,
    ErrorPageComponent,
    T2GuestDetailsComponent,
    CheckOutDropdownComponent,
    HeaderInfoDialog,
    HeaderComponent,
    SafePipe,
    ManageBookingComponent,
    FooterComponent,
    ScrollTopComponent,
    NoResultsComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatRadioModule,
    CalendarModule,
    FormsModule,
    BlockUIModule,
    ToastModule,
    NgbModule,
    DropdownModule,
    ButtonModule,
    OwlModule,
    OverlayPanelModule,
    InputTextModule,
    DialogModule,
    MatDialogModule,
    ConnectionServiceModule,
    MarkdownModule.forRoot()
  ],
  providers: [DatePipe, MessageService, ConnectionService ],
  bootstrap: [AppComponent],
  entryComponents: [
    CheckOutDropdownComponent,
    HeaderInfoDialog
  ]
})
export class AppModule { }
