import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataProviderService } from '../services/data-provider.service';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../services/loading.service';
import { MessageService } from 'primeng/primeng';
import { StorageService } from '../services/storage.service';
import { EventManagerService } from '../services/event-manager.service';
@Component({
  selector: 'app-guest-details',
  templateUrl: './guest-details.component.html',
  styleUrls: ['./guest-details.component.css']
})
export class GuestDetailsComponent implements OnInit {
  selectedRooms;
  submitBooking;
  guestDetails;
  checked = false;
  paymentGateway = 'airpay';
  supportedPaymentGateways = ['airpay'];
  selectedRoomImages;
  constructor(private dataProvider: DataProviderService,
              public eventManager: EventManagerService,
              private loadingService: LoadingService,
              private messageService: MessageService,
              private storageService: StorageService) {}
              
  ngOnInit() {
    window.scroll(0, 0);
    this.selectedRooms = this.dataProvider.selectedRooms;
    var hotelDetails = this.storageService.get(StorageService.hotelDetails);
    for (const room in hotelDetails.room_types ) {
      if(hotelDetails.room_types[room].room_id === this.selectedRooms.RoomsArray[0].RoomId) {
        this.selectedRoomImages = hotelDetails.room_types[room].images;
        break;
      }
    }
    if(hotelDetails.PaymentGateway && hotelDetails.PaymentGateway != ""){
      this.supportedPaymentGateways = hotelDetails.PaymentGateway.split(",");
      this.paymentGateway = this.supportedPaymentGateways[0].toLocaleLowerCase();
    }
    var loginUser = this.storageService.get("LOGIN_USER_ID");
    var loginSession = this.storageService.get("LOGIN_SESSION_ID");
    if(loginUser != null && loginSession != null){
      this.dataProvider.verifySession(loginUser,loginSession).subscribe(res => {
        if(res) this.eventManager.isUserLoggedIn = true;
        else this.eventManager.isUserLoggedIn = false; 
        },err => {this.eventManager.isUserLoggedIn = false;});
    }
  }

  onFormSubmit(form) {
    if (form.value.email !== form.value.confirmemail) {
      this.messageService.add({severity: 'error', summary: 'Email Mismatch',
      detail: 'Email and confirm email did not match'});
    } else if (form.value.contactno.length == 0 || parseInt(form.value.contactno).toString().length < 8 || parseInt(form.value.contactno).toString().length > 15) {
      this.messageService.add({severity: 'error', summary: 'Invalid Mobile No.',
      detail: 'Please enter a valid mobile number (8-15 digts).'});
    } else if(form.value.firstname.indexOf(' ') >= 0) {
      this.messageService.add({severity: 'error', summary: 'Invalid First Name',
      detail: 'First name cannot contain space.'});
    } else if(form.value.lastname.indexOf(' ') >= 0) {
      this.messageService.add({severity: 'error', summary: 'Invalid Last Name',
      detail: 'Last name cannot contain space.'});
    } else {
      this.guestDetails = form.value;
      this.dataProvider.guestDetails = form.value;
      this.storageService.set(StorageService.guestDetails, form.value);
      this.submitBooking = this.getBooking(form.value);
      this.loadingService.showLoading = true;
      if(this.paymentGateway == "postpaid"){
        this.submitBooking.payment_type = "1";
        this.submitBooking.login_user_id = this.storageService.get("LOGIN_USER_ID");
      }
      this.dataProvider.submitBooking(this.submitBooking).subscribe(response => {
        this.loadingService.showLoading = false;
        if (response.status === 'Failure') {
          this.eventManager.displayDialog({
            header: 'Error',
            detail: 'Booking failed.. Please contact hotel for support'
          });
          console.log('Error while booking - ' + response.problems[0].detail);
        } else {
          this.selectPaymentOption(response);
        }
      },
      error => {
        // Connectivity issue
        this.eventManager.viewChange({source: 'error-page'});
        this.loadingService.showLoading = false;
      });
    }
  }

  makeid() {
    let result = '';
    const length = 15;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getBooking(guestDetails) {
    const submitBooking = {
      checkin_date: this.selectedRooms.SearchCriteria.stayDateStart,
      checkout_date: this.selectedRooms.SearchCriteria.stayDateEnd,
      hotel_id: this.dataProvider.hotelId,
      reference_id: this.makeid(),
      customer: this.getCustomerDetails(guestDetails),
      special_request: guestDetails.specialrequests,
      device_type: detectmob(),
      comments: '',
      rooms: this.getRooms(guestDetails),
      payment_method: null,
      final_price_at_booking: this.getFinalPriceAtBooking(),
      final_price_at_checkout: {
        amount: 0,
        currency: 'INR'
      },
      partner_data: this.getPartnerData()
    };
    return submitBooking;
  }

  getCustomerDetails(guestDetails) {
    const customer = {
      first_name: guestDetails.firstname,
      last_name: guestDetails.lastname,
      phone_number: guestDetails.contactno,
      email: guestDetails.email,
      country: 'IND'
    };
    return customer;
  }

  getRooms(guestDetails) {
    const rooms = new Array<any>();
    for (let selectedRoom of this.selectedRooms.RoomsArray) {
      let  selectedRatePlans = selectedRoom.SelectedRatePlan;
      for (const selectedRatePlan of selectedRatePlans) {
        for ( let i = 0; i < selectedRatePlan.RatePlanCount; i++) {
          const room = {
            party: this.getParty(selectedRatePlan),
            traveler_first_name: guestDetails.firstname,
            traveler_last_name: guestDetails.lastname,
            roomdetails:{ room_id: selectedRoom.RoomId, rate_plan_id: selectedRatePlan.RatePlanId}
          };
          rooms.push(room);
        }
      }
    }
    return rooms;
  }

  getParty(selectedRatePlan) {
    if (selectedRatePlan.ChildAgeArray && selectedRatePlan.ChildAgeArray.length > 0) {
      return {
        adults: selectedRatePlan.GuestCount,
        children: selectedRatePlan.ChildAgeArray
      };
    } else {
      return {
        adults: selectedRatePlan.GuestCount
      };
    }
  }

  getFinalPriceAtBooking() {
    let bookingCost = 0;
    this.selectedRooms.RoomsArray.forEach(room => {
      bookingCost += room.RoomPrice;
    });
    const finalPrice = {
      amount: bookingCost,
      currency: 'INR'
    };
    return finalPrice;
  }

  getPartnerData() {
    const partnerData = {
      DomainId: this.dataProvider.domainId,
      HotelId: this.dataProvider.hotelId,
      RoomId: this.selectedRooms.RoomsArray[0].RoomId,
      RatePlanId: this.selectedRooms.RoomsArray[0].SelectedRatePlan[0].RatePlanId,
      PromotionId: this.selectedRooms.RoomsArray[0].SelectedRatePlan[0].PromotionId ?
                  this.selectedRooms.RoomsArray[0].SelectedRatePlan[0].PromotionId : ''
    };
    return partnerData;
  }

  selectPaymentOption(response) {
    if (this.paymentGateway === 'payu') {
      this.makePaymentPayU(response);
    } else if (this.paymentGateway === 'airpay') {
      this.makePaymentAirpay(response);
    } else if (this.paymentGateway === 'paytm') {

    } else if(this.paymentGateway == 'postpaid'){
      this.eventManager.viewChange(
        {
          source: 'display-booking-voucher',
          data: {
            bookingId: response.reservation.reservation_id,
            emailId: this.guestDetails.email
        }});
    }
  }

  onChangePaymentGateway(event) {
    this.paymentGateway = event.value;
  }
  makePaymentAirpay(response) {
    const obj = {
      hotelid: this.getHotelId(),
      buyerEmail: this.guestDetails.email,
      buyerPhone: this.guestDetails.contactno,
      buyerFirstName: this.guestDetails.firstname,
      buyerLastName: this.guestDetails.lastname,
      buyerAddress: '',
      buyerCity: '',
      buyerState: '',
      buyerCountry: '',
      buyerPinCode: '',
      orderid: response.reservation.reservation_id.slice(3, response.reservation.reservation_id.length),
      amount: this.getFinalPriceAtBooking().amount.toFixed(2),
      callbackurl: this.dataProvider.CallbackURL != null ? this.dataProvider.CallbackURL : environment.payment_callback_url,
      prod: environment.env === 'prod' ? true : false
    };
    this.dataProvider.post(obj, environment.payment_gateway_url);
  }

  makePaymentPayU(response) {
    const obj = {
      hotelid: this.getHotelId(),
      email: this.guestDetails.email,
      phone: this.guestDetails.contactno,
      firstname: this.guestDetails.firstname,
      txnid: response.reservation.reservation_id.slice(3, response.reservation.reservation_id.length),
      amount: this.getFinalPriceAtBooking().amount.toFixed(2),
      callbackurl: this.dataProvider.CallbackURL != null ? this.dataProvider.CallbackURL : environment.payment_callback_url,
      prod: environment.env === 'prod' ? true : false
    };
    
    this.dataProvider.post(obj, environment.payment_gatewayPayU_url);
  }

  getHotelId() {
    const partnerData = this.storageService.get(StorageService.partnerData);
    let hotelId;
    if (partnerData.HotelId) {
      hotelId = partnerData.HotelId;
    } else {
      hotelId = this.dataProvider.hotelId;
    }
    return hotelId;
  }

}

function detectmob() {
  if ( navigator.userAgent.match(/Android/i)
  || navigator.userAgent.match(/webOS/i)
  || navigator.userAgent.match(/iPhone/i)
  || navigator.userAgent.match(/iPad/i)
  || navigator.userAgent.match(/iPod/i)
  || navigator.userAgent.match(/BlackBerry/i)
  || navigator.userAgent.match(/Windows Phone/i)
  ) {
     return 'Mobile' ;
   } else {
     return 'Desktop' ;
   }
 }