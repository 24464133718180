import { Component, OnInit, OnDestroy } from '@angular/core';
import { SearchResultsService, RoomType } from '../services/search-results.service';
import { DataProviderService } from '../services/data-provider.service';
import { SearchCriteria, SelectedCriteria, SelectedRatePlan, SelectedRoom } from '../data/model';
import { LoadingService } from '../services/loading.service';
import { StorageService } from '../services/storage.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from '../services/utilities.service';
import { EventManagerService } from '../services/event-manager.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  rooms;
  clickedRoomArray: Array<number>;
  searchCriteria: SearchCriteria;
  selectedCriteria: SelectedCriteria;
  selectedRoomId = '';
  selectedRatePlanId = '';
  dialogData;
  displayDialog = false;
  totalSelectedAmount = 0;
  totalSelectedRooms = 0;
  displayResults = false;
  images = [];
  carouselOptions = {
    items: 1,
    dots: true,
    nav: false
  };
  searchCriteriaSubscription;
  InfantAgeLimit;
  displayConfirmSelection = false;
  selectedAdultsCount = 0;
  selectedKidsCount = 0;
  criteriaAdultsCount = 0;
  criteriaKidsCount = 0;
  isPromoCodeApplied = false;
  childCount = [];
  // navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
  constructor(private searchResult: SearchResultsService,
    public dataService: DataProviderService,
    private eventManager: EventManagerService,
    private loadingService: LoadingService,
    private storageService: StorageService,
    public utilities: UtilitiesService,
    private config: NgbCarouselConfig) {
    this.childCount = [{display:'1',value:1},{display:'2',value:2}];
  }
  ngOnInit() {
    this.unsubscribeEvents();
    this.clickedRoomArray = new Array<number>();
    this.searchCriteria = new SearchCriteria();
    this.selectedCriteria = new SelectedCriteria();
    this.config.interval = 1000000000;
    this.config.wrap = true;
    this.config.keyboard = false;
    this.InfantAgeLimit = this.dataService.getInfantAgeLimit();
    this.searchCriteriaSubscription = this.eventManager.searchCriteriaListener$.subscribe(
      criteria => {
        this.searchCriteria = this.setSearchCriteria(criteria);
        this.selectedCriteria = new SelectedCriteria();
        this.totalSelectedAmount = 0;
        this.totalSelectedRooms = 0;
        this.getRooms();
      });
  }

  getRooms() {
    this.loadingService.showLoading = true;
    this.dataService.getBookingAvail(this.searchCriteria).subscribe(result => {
      if (result[0].room_types_array.length === 0) {
        this.eventManager.viewChange({ source: 'no-results-found' });
        this.loadingService.showLoading = false;
        this.displayResults = false;
      } else {
        this.isPromoCodeApplied = false;
        if (this.isPromotionApplied(result[0])) { // If any promotion is available
          // Call hotel avail api to get list of promotions
          this.dataService.getHotelAvail(this.searchCriteria.stayDateStart, this.searchCriteria.stayDateEnd, this.searchCriteria.promoCode)
            .subscribe(hotelAvailResult => {
              this.dataService.promotions = hotelAvailResult.Promotions;
              if (this.searchCriteria.promoCode && this.searchCriteria.promoCode != null) {
                var promo = hotelAvailResult.Promotions.find(p => p.promo_code === this.searchCriteria.promoCode);
                if (promo) {
                  var bookAvail = result[0].room_types_array.find(r => r.partner_data.PromotionId == promo.id);
                  this.isPromoCodeApplied = (bookAvail != null);
                }
              }
              this.processBookingAvailData(result);
            });
        } else {
          this.processBookingAvailData(result);
        }
      }
      if (window.innerWidth < 992) {
        this.eventManager.scrollToResults();
      } else {
        window.scroll(0, 0);
      }
    },
      error => {
        // Connectivity issue
        this.eventManager.viewChange({ source: 'error-page' });
        this.loadingService.showLoading = false;
      }
    );
  }

  isPromotionApplied(result) {
    for (const room of result.room_types_array) {
      if (room.partner_data.PromotionId.length > 0) {
        return true;
      }
    }
    return false;
  }

  maxGuests;
  processBookingAvailData(result) {
    this.storageService.set(StorageService.partnerData, result[0].room_types_array[0].partner_data);
    this.updateHotelDomainId(result[0].room_types_array[0].partner_data);
    this.rooms = this.searchResult.getAvailableRooms(result);
    this.loadingService.showLoading = false;
    this.displayResults = true;
    this.eventManager.viewChange({ source: 'search-criteria-results' });
  }

  updateHotelDomainId(partnerData) {
    this.dataService.hotelId = partnerData.HotelId;
    this.dataService.domainId = partnerData.DomainId;
    this.storageService.set(StorageService.hotelid, partnerData.HotelId);
    this.storageService.set(StorageService.domainId, partnerData.DomainId);
  }

  onClickRoomDetails(clickedRoom) {
    if (this.clickedRoomArray.indexOf(clickedRoom) >= 0) {
      this.clickedRoomArray.splice(this.clickedRoomArray.indexOf(clickedRoom), 1);
    } else {
      this.clickedRoomArray.push(clickedRoom);
    }
  }

  onRatePlanChange(room, rateplan, event?) {
    room.TotalPrice = 0;
    let value;
    this.totalSelectedAmount = 0;
    this.totalSelectedRooms = 0;
    if (event && event === 'add') {
      // Add rooms
      rateplan.RatePlanCount += 1;
      room.RoomCount += 1;
      this.addToSelectedCriteria(room, rateplan);
    } else if (event && event === 'remove') {
      // Remove rooms
      rateplan.RatePlanCount -= 1;
      room.RoomCount -= 1;
      this.removeFromSelectedCriteria(room, rateplan);
    } else {
      if (event && event.value.name) {
        value = parseInt(event.value.name);
      } else {
        value = 1;
      }
      if (rateplan.RatePlanCount < value) {
        // Add rooms
        rateplan.RatePlanCount = value;
        this.selectedRoomId = room.RoomId;
        this.selectedRatePlanId = rateplan.RatePlanId;
        this.addToSelectedCriteria(room, rateplan);
      } else {
        // Remove rooms
        rateplan.RatePlanCount = value;
        this.removeFromSelectedCriteria(room, rateplan);
      }
    }

    for (const selectedRoom of this.selectedCriteria.RoomsArray) {
      let roomCount = 0;
      selectedRoom.RoomPrice = 0;
      for (const selectedRatePlan of selectedRoom.SelectedRatePlan) {
        roomCount += selectedRatePlan.RatePlanCount;
        this.totalSelectedRooms += selectedRatePlan.RatePlanCount;
        this.totalSelectedAmount += selectedRatePlan.RatePlanPrice * selectedRatePlan.RatePlanCount;
        selectedRoom.RoomPrice += (selectedRatePlan.RatePlanPrice + selectedRatePlan.RatePlanTax) * selectedRatePlan.RatePlanCount;
      }
      if (selectedRoom.RoomId === room.RoomId) {
        room.RoomCount = roomCount;
      }
    }

    if (this.totalSelectedRooms === 0) {
      this.selectedRoomId = '';
      this.selectedRatePlanId = '';
    }

    this.refreshRoomInventory(room);
  }



  addToSelectedCriteria(room, rateplan) {
    // If room already exist in seleceted criteria
    const existingRoom = this.selectedCriteria.RoomsArray.find((selectedRoom) => {
      return selectedRoom.RoomId === room.RoomId;
    });
    if (existingRoom) { // If room exist in selected criteria
      // Check if rate plan exist or not
      const existingRatePlan = existingRoom.SelectedRatePlan.find((selectedRatePlan) => {
        return selectedRatePlan.RatePlanId === rateplan.RatePlanId &&
          selectedRatePlan.GuestCount === rateplan.GuestCount &&
          selectedRatePlan.ChildCount === rateplan.ChildCount &&
          selectedRatePlan.InfantCount === rateplan.InfantCount;
      });
      if (existingRatePlan) {
        // If rate plan exist then just update rateplan count
        // existingRatePlan.RatePlanCount+=1
        existingRatePlan.RatePlanCount = rateplan.RatePlanCount;
        existingRatePlan.RatePlanPrice = rateplan.RatePlanPrice;
        existingRatePlan.NonPromotionalPrice = rateplan.NonPromotionalPrice;
        existingRatePlan.RatePlanTax = rateplan.RatePlanTax;
        existingRatePlan.RatePlanTaxDescription = rateplan.RatePlanTaxDescription;
      } else {// If rate plan doesn't exist then add that rate plan in room
        const selectedRatePlan = new SelectedRatePlan();
        selectedRatePlan.RatePlanId = rateplan.RatePlanId;
        selectedRatePlan.GuestCount = rateplan.GuestCount;
        selectedRatePlan.ChildCount = rateplan.ChildCount;
        selectedRatePlan.InfantCount = rateplan.InfantCount;
        selectedRatePlan.ChildAgeArray = rateplan.ChildAgeArray;
        // selectedRatePlan.RatePlanCount += 1;
        selectedRatePlan.RatePlanCount = rateplan.RatePlanCount;
        selectedRatePlan.RatePlanName = rateplan.RatePlanName;
        selectedRatePlan.MealPlan = rateplan.MealPlan.name;
        selectedRatePlan.RatePlanPrice = rateplan.RatePlanPrice;
        selectedRatePlan.NonPromotionalPrice = rateplan.NonPromotionalPrice;
        selectedRatePlan.RatePlanTax = rateplan.RatePlanTax;
        selectedRatePlan.RatePlanTaxDescription = rateplan.RatePlanTaxDescription;
        if (rateplan.Promotion && rateplan.Promotion.PromotionId) {
          selectedRatePlan.PromotionId = rateplan.Promotion.PromotionId;
        }
        existingRoom.SelectedRatePlan.push(selectedRatePlan);
      }
    } else {// If room doesn't exist in selected criteria then add a new room
      const selectedRoom = new SelectedRoom();
      selectedRoom.RoomId = room.RoomId;
      selectedRoom.RoomName = room.RoomName;
      selectedRoom.SelectedRatePlan = new Array<SelectedRatePlan>();
      const selectedRatePlan = new SelectedRatePlan();
      selectedRatePlan.RatePlanId = rateplan.RatePlanId;
      selectedRatePlan.GuestCount = rateplan.GuestCount;
      selectedRatePlan.ChildCount = rateplan.ChildCount;
      selectedRatePlan.InfantCount = rateplan.InfantCount;
      selectedRatePlan.ChildAgeArray = rateplan.ChildAgeArray;
      //  selectedRatePlan.RatePlanCount += 1;
      selectedRatePlan.RatePlanCount = rateplan.RatePlanCount;
      selectedRatePlan.MealPlan = rateplan.MealPlan.name;
      selectedRatePlan.RatePlanName = rateplan.RatePlanName;
      selectedRatePlan.RatePlanPrice = rateplan.RatePlanPrice;
      selectedRatePlan.NonPromotionalPrice = rateplan.NonPromotionalPrice;
      selectedRatePlan.RatePlanTax = rateplan.RatePlanTax;
      selectedRatePlan.RatePlanTaxDescription = rateplan.RatePlanTaxDescription;
      if (rateplan.Promotion && rateplan.Promotion.PromotionId) {
        selectedRatePlan.PromotionId = rateplan.Promotion.PromotionId;
      }
      selectedRoom.SelectedRatePlan.push(selectedRatePlan);
      this.selectedCriteria.RoomsArray.push(selectedRoom);
    }
  }

  removeFromSelectedCriteria(room, rateplan) {
    // If room already exist in seleceted criteria
    const existingRoom = this.selectedCriteria.RoomsArray.find((selectedRoom) => {
      return selectedRoom.RoomId === room.RoomId;
    });
    const existingRatePlan = existingRoom.SelectedRatePlan.find((selectedRatePlan) => {
      return selectedRatePlan.RatePlanId === rateplan.RatePlanId && selectedRatePlan.GuestCount === rateplan.GuestCount;
    });
    // existingRatePlan.RatePlanCount -= 1;
    existingRatePlan.RatePlanCount = rateplan.RatePlanCount;
    let ratePlanIndex;
    if (existingRatePlan.RatePlanCount === 0) {// Remove the rate plan from rooms
      existingRoom.SelectedRatePlan.some((r, index) => {
        if (rateplan.RatePlanId === r.RatePlanId && rateplan.GuestCount === r.GuestCount) {
          ratePlanIndex = index;
          return true;
        }
      });
      if (ratePlanIndex && ratePlanIndex >= 0) {
        existingRoom.SelectedRatePlan.splice(ratePlanIndex, 1);
      }
      if (existingRoom.SelectedRatePlan.length === 0) {// If there is no rate plan in the room then remove that room as well
        const roomIndex = this.selectedCriteria.RoomsArray.findIndex(selectedRoom => selectedRoom.RoomId === existingRoom.RoomId);
        if (roomIndex >= 0) {
          this.selectedCriteria.RoomsArray.splice(roomIndex, 1);
        }
      }
    }
    existingRatePlan.RatePlanPrice = rateplan.RatePlanPrice;
  }

  onReserveBooking(room?, rateplan?) {
    if (room && rateplan) {
      this.onRatePlanChange(room, rateplan);
    }
    if (this.validateSelectedRooms()) {
      this.confirmSelection();
    } else {
      this.displayConfirmSelection = true;
    }
  }

  onAdultCountChange(room, rateplan, event?) {
    rateplan.GuestCount = event.value.value;
    this.selectedAdultsCount = rateplan.GuestCount;
  }

  onChildCountChange(room, rateplan, event?) {
    rateplan.ChildCount = event.value.value;
    this.selectedKidsCount = rateplan.ChildCount;
  }

  validateSelectedRooms() {
    this.selectedAdultsCount = 0;
    this.selectedKidsCount = 0;
    for (const room of this.selectedCriteria.RoomsArray) {
      for (const rateplan of room.SelectedRatePlan) {
        this.selectedAdultsCount += (rateplan.GuestCount) * rateplan.RatePlanCount;
       // this.selectedKidsCount += (rateplan.ChildCount + rateplan.InfantCount) * rateplan.RatePlanCount;
       this.selectedKidsCount += (rateplan.ChildCount) * rateplan.RatePlanCount;
      }
    }
    if (this.selectedAdultsCount != this.searchCriteria.adultsCount ||
      this.selectedKidsCount != this.searchCriteria.kidsCount) {
      return false;
    } else {
      return true;
    }
  }

  confirmSelection() {
    this.selectedCriteria.SearchCriteria = this.searchCriteria;
    this.dataService.setReservationCart(this.selectedCriteria);
    this.eventManager.viewChange({ source: 'searchResults' });
  }

  onCloseConfirmSelection(event?) {
    this.displayConfirmSelection = false;
    this.selectedAdultsCount = 0;
    this.selectedKidsCount = 0;
  }

  refreshRoomInventory(room) {
    for (const rateplan of room.RoomRatePlans) {
      const inventoryArray = new Array<{}>();
      let totalSelectedRatePlans = 0;
      for (const r of room.RoomRatePlans) {
        if (r.RatePlanId !== rateplan.RatePlanId ||
          (r.RatePlanId === rateplan.RatePlanId &&
            (r.GuestCount !== rateplan.GuestCount) ||
            r.ChildCount !== rateplan.ChildCount ||
            r.InfantCount !== rateplan.InfantCount)) {
          totalSelectedRatePlans += r.RatePlanCount;
        }
      }

      for (let i = 0; i <= room.Inventory; i++) {
        if (i <= room.Inventory - totalSelectedRatePlans) {
          inventoryArray.push({
            name: i.toString(), display: i > 0 ? i.toString() +
              '  (₹' + this.utilities.numberWithCommas(i * rateplan.RatePlanBasePrice) + ')' : '0'
          });
        }
      }
      rateplan.InventoryArray = inventoryArray;

      // for (let i = 0; i <= room.MaxGuests; i++) {

      // }
      // rateplan.InventoryArray = inventoryArray;
    }
  }

  setSearchCriteria(criteria) {
    const copyCriteria = JSON.parse(JSON.stringify(criteria));
    return copyCriteria;
  }

  openDialog(roomDetails) {
    this.dialogData = roomDetails;
    this.displayDialog = true;
  }

  getDialogSize() {
    if (window.innerWidth < 768) {
      return {
        width: window.innerWidth * 0.7 + 'px',
        height: window.innerHeight * 0.6 + 'px',
        overflow: 'auto'
      };
    } else {
      return {
        width: window.innerWidth * 0.7 + 'px',
        height: window.innerHeight * 0.8 + 'px'
      };
    }

  }

  getDialogSizeConfirmSelection() {
    return {
      width: window.innerWidth * 0.5 + 'px',
      height: window.innerHeight * 0.5 + 'px'
    };
  }

  onClickCancellationPolicy(policy) {
    this.eventManager.displayDialog({
      header: 'Cancellation Policy',
      detail: 'Details - ' + policy
    });
  }


  unsubscribeEvents() {
    if (this.searchCriteriaSubscription) {
      this.searchCriteriaSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.unsubscribeEvents();
  }

}
