import { Injectable } from '@angular/core';
import { Theme, themes } from '../data/theme';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  currentThemes;
  activatedTheme;
  constructor(private meta: Meta) { }

  // setActiveTheme(themename) {
  //   for (const theme of themes) {
  //     if (theme.themename == themename) {
  //       Object.keys(theme.properties).forEach(property => {
  //         document.documentElement.style.setProperty(
  //           property,
  //           theme.properties[property]
  //         );
  //       });
  //     }
  //   }
  // }

  setActiveTheme(hotelId, domainId,partnercode) {
    let themeSet = false;
    // Set theme by hotel id
    for (const theme of this.currentThemes) {
      if (theme.hotelId && theme.hotelId.toLowerCase() === hotelId.toLowerCase()) {
        themeSet = true;
        this.activateTheme(theme);
        break;
      }
    }
    if (!themeSet && domainId) {
      for (const theme of this.currentThemes) {
        if (theme.domainId && theme.domainId.toLowerCase() === domainId.toLowerCase()) {
          themeSet = true;
          this.activateTheme(theme);
          break;
        }
      }
    }
    if (!themeSet && partnercode) {
      for (const theme of this.currentThemes) {
        if (theme.partnercode && theme.partnercode.toLowerCase() === partnercode.toLowerCase()) {
          themeSet = true;
          this.activateTheme(theme);
          break;
        }
      }
    }
    if (!themeSet) {
      this.activateTheme();
    }
  }

  activateTheme(theme?) {
    if (theme) {
      this.activatedTheme = theme;
      document.documentElement.style.setProperty('--theme-light', theme.colorLight);
      document.documentElement.style.setProperty('--theme-dark', theme.colorDark);
      if(theme.colorLightHeader)
        document.documentElement.style.setProperty('--theme-light-header', theme.colorLightHeader);
      else 
        document.documentElement.style.setProperty('--theme-light-header', theme.colorLight);
      if(theme.fontColor != null) document.documentElement.style.setProperty('--theme-text-color', theme.fontColor);
      //if(theme.fontColor != null) document.documentElement.style.setProperty('--theme-font-color-dark', theme.fontColorDark);
      this.meta.removeTag('name="theme-color"');
      this.meta.addTag({name: 'theme-color', content: theme.colorLight});
    } else {
      document.documentElement.style.setProperty('--theme-light', '#E9A83A');
      document.documentElement.style.setProperty('--theme-dark', '#321800');
      document.documentElement.style.setProperty('--theme-light-header', '#E9A83A');
      this.meta.removeTag('name="theme-color"');
      this.meta.addTag({name: 'theme-color', content: '#E9A83A'});
    }
  }
}
