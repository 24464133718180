import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public static hotelid = 'HOTEL_ID';
  public static hotelDetails = 'HOTEL_DETAILS';
  public static guestDetails = 'GUEST_DETAILS';
  public static paymentDetails = 'PAYMENT_DETAILS';
  public static checkin = 'CHECKIN_DATE';
  public static checkout = 'CHECKOUT_DATE';
  public static domainId = 'DOMAIN_ID';
  public static domainHotels = 'DOMAIN_HOTELS';
  public static partnercode = 'PARTNER_CODE';
  public static isEnvPartnerCode = 'IS_ENV_PARTNER_CODE';
  public static initialparams = 'INITIAL_PARAMS';
  public static params = 'PARAMS';
  public static partnerData = 'PARTNER_DATA';

  constructor() { }
  get(storageKey): any {
    try{
      if (sessionStorage.getItem(storageKey))
        return JSON.parse(sessionStorage.getItem(storageKey));
    }catch(e){
      return null;
    }
  }

  set(storageKey, data: any) {
      sessionStorage.setItem(storageKey,JSON.stringify(data));
  }

  remove(storageKey) {
      sessionStorage.removeItem(storageKey);
  }

  clearCache(){
    this.remove(StorageService.hotelid);
    this.remove(StorageService.hotelDetails);
    this.remove(StorageService.guestDetails);
    this.remove(StorageService.paymentDetails);
    this.remove(StorageService.checkin);
    this.remove(StorageService.checkout);
    this.remove(StorageService.domainId);
    this.remove(StorageService.domainHotels);
    this.remove(StorageService.partnercode);
    this.remove(StorageService.isEnvPartnerCode);
    this.remove(StorageService.initialparams);
    this.remove(StorageService.params);
    this.remove(StorageService.partnerData);
  }
}
