import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import * as jQuery from 'jquery';
@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.css']
})
export class NoResultsComponent implements OnInit {

  @ViewChild('noResults') noResults;
  constructor() { }

  ngOnInit() {
    this.setHeight();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerWidth;
    if (windowHeight > 600) {
      windowHeight = windowHeight * 0.3;
    } else {
      windowHeight = windowHeight * 0.5;
    }
    jQuery('.no-results-outer').css('height', windowHeight);
  }

  getIconStyle() {
    return {
      top: ( this.noResults.nativeElement.clientHeight / 2 - 80 ) + 'px',
      left: ( this.noResults.nativeElement.clientWidth  / 2 - 80 ) + 'px'
    }
  }
}
