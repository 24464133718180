import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { HotelDetailsRS, ReservationCart, ReservationRoom, TransactionDetails } from '../data/model';
import { StorageService } from './storage.service';
import { UtilitiesService } from './utilities.service';
import { Router } from '@angular/router';
import * as Crypt from 'crypt';
import * as SHA1 from 'sha1';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataProviderService {
  data = [];
  reservationCart;
  selectedRooms;
  guestDetails;
  transactionDetails;
  bookingConfirmation;
  promotions;
  domainId;
  hotelId: string;
  partnerCode;
  Lang;
  Currency: string;
  TotalAdultsCount : number;
  TotalKidsCount;
  RoomsCriteria;
  Children;
  CheckIn;
  CheckOut;
  RoomCode: string = null;
  RateCode: string = null;
  nRooms;
  nAdults;
  nChildrens;
  childAgeArray;
  promoCode: string = null;
  CallbackURL: string = null;
  isEnvironmentPartnerCode = true;
  domainHotels = [];
  template = 't1';
  constructor(private http: HttpClient,
              private utilities: UtilitiesService,
              private storageService: StorageService,
              private router: Router) {

  }

  setAdults(adults: number) {
    if (Number.isNaN(adults)) {
      this.TotalAdultsCount = null;
    } else {
      this.TotalAdultsCount = adults;
    }
  }

  setChildren(children) {
    if (children === '') {
      this.Children = null;
    } else {
      this.Children = children;
    }
  }

  setCheckIn(checkin) {
    if (checkin === '') {
      this.CheckIn = null;
    } else {
      this.CheckIn = checkin;
    }
  }

  setCheckOut(checkout) {
    if (checkout === '') {
      this.CheckOut = null;
    } else {
      this.CheckOut = checkout;
    }
  }

  setTransationDetails(transactionDetail?) {
    if (!transactionDetail) {
      return this.bookingConfirmation;
    }
    this.transactionDetails = new TransactionDetails();
    this.transactionDetails.TransactionID = transactionDetail[0];
    this.transactionDetails.ApTransactionID = transactionDetail[1];
    this.transactionDetails.Amount = transactionDetail[2];
    this.transactionDetails.TransactionStatus = transactionDetail[3];
    this.transactionDetails.Message = transactionDetail[4];
    this.transactionDetails.MerchantID = transactionDetail[5];
    this.transactionDetails.Username = transactionDetail[6];
    if (transactionDetail[3] === '200') {
      this.bookingConfirmation = 'Confirmed';
    } else {
      this.bookingConfirmation = 'Cancelled';
    }
    return this.transactionDetails;
  }

  getThemeData(): Observable<any> {
      return this.http.get('assets/hotel-data/hotel-theme.json');
  }

  getPrivacyPolicy(): Observable<any> {
    return this.http.get('assets/hotel-data/privacy-policy.md', {responseType: 'text'});
  }
  getHotelRules(): Observable<any> {
    return this.http.get('assets/hotel-data/hotel-rules.md', {responseType: 'text'});
  }

  getHotelDetails() : Observable<HotelDetailsRS> {
    let url = null;
    if (this.isEnvironmentPartnerCode && this.domainId) {
       url = environment.serviceUrl + '/hoteldetails?partner_code=' + this.partnerCode +
      '&h_id=' + this.hotelId +
      '&d_id=' + this.domainId;
    } else {
      url = environment.serviceUrl + '/hoteldetails?partner_code=' + this.partnerCode +
      '&h_id=' + this.hotelId;
    }
    return this.httpGet<HotelDetailsRS>(url);
  }

  getHotelAvail(stayDateStart, stayDateEnd, promoCode): Observable<any> {
    const url = environment.serviceUrl + '/hotelavail?partner_code=' + this.partnerCode +
                '&hotel_id=' + this.hotelId + '&start_date=' + this.utilities.transformDate(stayDateStart, 'dd-MMM-yyyy') +
                '&end_date=' + this.utilities.transformDate(stayDateEnd, 'dd-MMM-yyyy') + ((promoCode != undefined && promoCode != null && promoCode != "")?'&promo_code='+promoCode:'');
    return this.httpGet(url);
  }

  getBookingAvail(criteria): Observable<any> {
    const responseArray = new Array<Observable<any>>();
    const url = environment.serviceUrl + '/bookingavail?partner_code=' + this.partnerCode;
    for (const room of criteria.rooms) {
      const body = 'hotel={\'hotel_id\':\'' + this.hotelId + '\'}&start_date=' + criteria.stayDateStart +
                    '&end_date=' + criteria.stayDateEnd + 
                    ((criteria.promoCode != undefined && criteria.promoCode && criteria.promoCode != "")?'&promo_code='+criteria.promoCode:'')+ 
                    '&party=[{' + this.getParty(room) + '}]' +
                    '&room_code=' + this.RoomCode +
                    '&rate_code=' + this.RateCode +
                    '&query_key=3cc343af545749818805dd199a914dee_IN273314_270320151728_1_1';
      const response = this.httpPost(url, body);
      responseArray.push(response);
    }
    return forkJoin(responseArray);
  }

  getParty(room) {
    if (room.KidsAgeArray.length > 0) {
      return '\'adults\':' + room.AdultsCount + ',' + '\'children\':' + '[' + room.KidsAgeArray + ']';
    } else {
      return '\'adults\':' + room.AdultsCount;
    }
  }

  submitBooking(body) {
    const url = environment.serviceUrl + '/submitbooking?partner_code=' + this.partnerCode;
    return this.httpPost(url, body, 'application/json');
  }

  cancelBooking(hotelid, reservationid) {
    const url = environment.serviceUrl + '/cancelbooking?partner_code=' + this.partnerCode;
    const body = '{ \'hotel_id\':' + '\'' + hotelid + '\',\'reservation_id\':' + '\'' + reservationid + '\'' + '}';
    return this.httpPost(url, body, 'application/json');
  }

  updateBooking(transactionDetail) {
    const url = environment.serviceUrl + '/updatebooking?partner_code=' + this.partnerCode;
    const body = transactionDetail;
    return this.httpPost(url, body, 'application/json');
  }

  checkBooking(params) {
    const url = environment.serviceUrl + '/checkbooking?hotel_id=' + params.hotelId +
                '&email_id=' + params.email + '&reservation_id=' + params.bookingId +
                '&partner_code=' + params.partnercode;
    return this.httpGet(url);
  }

  getCancellationFees(params) {
    const url = environment.serviceUrl + '/getcancellationfee?partner_code=' + params.partnercode + '&reservation_id=' + params.bookingid;
    return this.httpGet(url);
  }
  setReservationCart(value) {
    this.selectedRooms = value;
    this.reservationCart = new ReservationCart();
    this.reservationCart.CheckinDate = value.SearchCriteria.checkinDate;
    this.reservationCart.CheckoutDate = value.SearchCriteria.checkoutDate;
    this.reservationCart.RoomNights = value.SearchCriteria.roomNights;
    this.reservationCart.NumberOfKids = value.SearchCriteria.kidsCount;
    this.reservationCart.ReservationRooms = new Array<ReservationRoom>();
    for (const room of value.RoomsArray) {
      for (const rateplan of room.SelectedRatePlan) {
        for (let i = 0; i < rateplan.RatePlanCount; i++) {
          const resRoom = new ReservationRoom();
          resRoom.RoomName = room.RoomName;
          resRoom.RatePlanName = rateplan.RatePlanName;
          resRoom.MealPlan = rateplan.MealPlan;
          resRoom.AdultsCount = rateplan.GuestCount;
          resRoom.ChildCount = rateplan.ChildCount;
          resRoom.InfantCount = rateplan.InfantCount;
          resRoom.ChildAgeArray = rateplan.ChildAgeArray;
          resRoom.RoomPrice = rateplan.RatePlanPrice;
          resRoom.RoomTax = rateplan.RatePlanTax;
          resRoom.RoomPriceNonPromoional = rateplan.NonPromotionalPrice;
          resRoom.RoomTaxDescription = rateplan.RatePlanTaxDescription;
          this.reservationCart.NumberOfAdults += rateplan.GuestCount;
          this.reservationCart.TotalAmount += rateplan.RatePlanPrice;
          if (rateplan.NonPromotionalPrice) {
            this.reservationCart.TotalNonPromotionalAmount += rateplan.NonPromotionalPrice;
          }
          this.reservationCart.TotalTaxAmount += rateplan.RatePlanTax;
          this.reservationCart.ReservationRooms.push(resRoom);
        }
      }
    }
  }

  getCloseoutDates(startDate) {
    const minDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
    const maxDate = new Date(startDate.getFullYear(), startDate.getMonth(), 0);
    this.getHotelAvail(minDate, maxDate,null).subscribe(result => {
      return this.getCloseoutData(minDate, maxDate, result);
    });
  }

  getCloseoutData(startDate, endDate, result) {
    const returnData = [];
    for (const date = new Date(startDate); date.getTime() <= endDate.getTime(); date.setDate(new Date(date).getDate() + 1)) {
      let hasEntry = false;
      for (const rate of result.Rates) {
        const tempDate = new Date(rate.stay_date);
        const stayDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate());
        if (date.getTime() === stayDate.getTime() && rate.inventory > 0) {
          returnData.push({date: new Date(date), isClosed: false});
          hasEntry = true;
          break;
        }
      }
      if (!hasEntry) {
        returnData.push({date: new Date(date), isClosed: true});
      }
    }
    return returnData;
  }

  getReservationCart() {
    return this.reservationCart;
  }

  getChildAgeLimit() {
    const hotelDetails = this.storageService.get(StorageService.hotelDetails);
    if (hotelDetails && hotelDetails.ChildAgeLimit > 0) {
      return hotelDetails.ChildAgeLimit;
    } else {
      console.log('Checklist Child age limit is not set');
      return 12;
    }
  }

  getInfantAgeLimit() {
    const hotelDetails = this.storageService.get(StorageService.hotelDetails);
    if (hotelDetails && hotelDetails.InfantAgeLimit > 0) {
      return hotelDetails.InfantAgeLimit;
    } else {
      console.log('Checklist Infant age limit is not set');
      return 5;
    }
  }
  httpGet<T>(url: string): Observable<any> {
    const httpHeaders = new HttpHeaders(
      {Authorization: environment.authorization});
    const options = {
      headers: httpHeaders
    };
    return this.http.get(url, options);
  }

  httpPost<T>(url: string, args: any, contentType = 'application/x-www-form-urlencoded'): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: environment.authorization,
      'Content-Type': contentType
    });
    const options = {
        headers: httpHeaders
    };
    return this.http.post(url, args, options);
  }

  post(obj, url) {
    const mapForm = document.createElement('form');
    mapForm.target = '_self';
    mapForm.method = 'POST';
    mapForm.action = url;
    Object.keys(obj).forEach((param) => {
      const mapInput = document.createElement('input');
      mapInput.type = 'hidden';
      mapInput.name = param;
      mapInput.setAttribute('value', obj[param]);
      mapForm.appendChild(mapInput);
  });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  routeToHomePage(hid) {
    if (this.isEnvironmentPartnerCode) {
      this.router.navigate([''],
      {queryParams: {
                    hid: hid,
                    did: this.domainId,
                    checkin: this.utilities.transformDate(new Date(this.CheckIn), 'yyyy-MM-dd'),
                    checkout: this.utilities.transformDate(new Date(this.CheckOut), 'yyyy-MM-dd')
      }});
    } else {
      this.router.navigate([''],
      {queryParams: {
                    hid: hid,
                    partnercode: this.partnerCode,
                    checkin: this.utilities.transformDate(new Date(this.CheckIn), 'yyyy-MM-dd'),
                    checkout: this.utilities.transformDate(new Date(this.CheckOut), 'yyyy-MM-dd')
      }});
    }
  }
  //http://localhost:47162//mantrasdirect/modifybooking?partner_code=CLARKS 
  checkBookingModification(partnerCode,booking:bookingData) {
    const url = environment.serviceUrl + '/checkmodification?partner_code='+partnerCode;
    const body = JSON.stringify(booking);
    return this.httpPost(url, body, 'application/json');
  }

  modifyBooking(partnerCode,booking:bookingData) {
    const url = environment.serviceUrl + '/modifybooking?partner_code='+partnerCode;
    const body = JSON.stringify(booking);
    return this.httpPost(url, body, 'application/json');
  }

  authenticate(userId, password) {
    const url = environment.serviceUrl + '/authenticate?partner_code='+this.partnerCode;
    var auth = new authData();
    auth.user_id = userId;
    auth.password = password;
    let passwordToValidate = stringToBytes(password);
    console.log("bytesBase64 Encryption: ", Crypt.bytesToBase64(SHA1(passwordToValidate, { "asBytes": "true" })));
    auth.password = Crypt.bytesToBase64(SHA1(passwordToValidate, { "asBytes": "true" }));
    auth.domain_id = this.domainId;
    const body = JSON.stringify(auth);
    return this.httpPost(url, body, 'application/json');
  }

  verifySession(userId, sessionId) {
    const url = environment.serviceUrl + '/verifysession?partner_code='+this.partnerCode;
    var auth = new authData();
    auth.user_id = userId;
    auth.session_id = sessionId;
    if(this.domainId == null || this.domainId == undefined) this.domainId = this.storageService.get(StorageService.domainId);
    auth.domain_id = this.domainId;
    const body = JSON.stringify(auth);
    return this.httpPost(url, body, 'application/json');
  }

  logout(userId, sessionId) {
    const url = environment.serviceUrl + '/logout?partner_code='+this.partnerCode;
    var auth = new authData();
    auth.user_id = userId;
    auth.session_id = sessionId;
    const body = JSON.stringify(auth);
    return this.httpPost(url, body, 'application/json');
  }

  getMaxGuestsCount() {
    var hotelDetails = this.storageService.get(StorageService.hotelDetails);
    var maxCount = 1;
    for (const room1 in hotelDetails.room_types) {
      if(maxCount < hotelDetails.room_types[room1].max_occupancy)
        maxCount = hotelDetails.room_types[room1].max_occupancy;
    }
    return maxCount;
  }
}

function stringToBytes(str) {
  for (var bytes = [], i = 0; i < str.length; i++)
      bytes = bytes.concat([str.charCodeAt(i) & 0xff, str.charCodeAt(i) / 256 >>> 0]);
  return bytes;
}
export class bookingData{
  booking_id:string;
  checkin_date:string;
  checkout_date:string;
  customer:customer;
}
export class customer {
  first_name:string;
  last_name:string;
  email:string;
  mobile:string;
}

export class authData {
  user_id:string;
  domain_id:string;
  hotel_id:string;
  password:string;
  session_id:string;
}