import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent{

  dialogRef;
  hotelLogo;
  websiteurl;
  contacts;
  @Input() 
  hotelDetails : HotelInfo;
  constructor(private dialog: MatDialog,
              private dataService: DataProviderService,
              public eventManager: EventManagerService,
              private storageService: StorageService,
              private router: Router,
              public themeService: ThemeService,
              public messageService: MessageService,
              public loadingService: LoadingService) {
}

ngOnInit(){
  this.hotelLogo = environment.images_url + this.hotelDetails.hotel_id.toLowerCase() + '/logo.png';
  this.websiteurl = this.getWebsiteURL();
  this.contacts = this.hotelDetails.phone.split(',');
  var loginUser = this.storageService.get("LOGIN_USER_ID");
  var loginSession = this.storageService.get("LOGIN_SESSION_ID");
  if(loginUser && loginSession) {
    this.dataService.verifySession(loginUser,loginSession).subscribe(res => {
      if(res) this.eventManager.isUserLoggedIn = true;
      else this.eventManager.isUserLoggedIn = false; 
      },err => {this.eventManager.isUserLoggedIn = false;});
  }
}

onClickHotelInfo(){
  this.dialogRef = this.dialog.open(HeaderInfoDialog, {
    width: window.innerWidth*0.8+'px',
    maxHeight: window.innerHeight*0.8+'px',
    data: this.hotelDetails
  });
}

onClickManageBooking(){
  let initialparams = this.storageService.get(StorageService.initialparams);
  let currentparams = this.storageService.get(StorageService.params);
  
  if(currentparams && currentparams.res){
    initialparams['view']='voucher';
    this.router.navigate([''],
    {queryParams:initialparams});
  }
  else{
    this.eventManager.viewChange({source:'manage-booking'});
  }
}

onClickHomeButton(){
  location.href = this.websiteurl;
  // let initialparams = this.storageService.get(StorageService.initialparams);
  // let currentparams = this.storageService.get(StorageService.params);
  
  // if(currentparams && currentparams.res){
  //   this.router.navigate([''],
  //   {queryParams:initialparams});
  // }
  // else{
  //   this.eventManager.viewChange({source:'search-criteria-results'});
  // }
}

  getWebsiteURL(){
    let websiteurl;
    if (!this.hotelDetails.websiteurl) {
      websiteurl = '';
    } else {
      websiteurl = this.hotelDetails.websiteurl;
    }
    return websiteurl;
  }

  showLoginDialog = false;
  userId;
  password;
  errorMessage;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  onLogin(){
    this.loadingService.showLoading = true;
    var sessionId = this.dataService.authenticate(this.userId,this.password).subscribe(res => {
      this.storageService.set("LOGIN_USER_ID",this.userId);
      this.storageService.set("LOGIN_SESSION_ID",res);
      this.eventManager.isUserLoggedIn = true;
      this.messageService.add({severity: 'success', summary: 'Login Successfully'});
      this.showLoginDialog = false;
      this.loadingService.showLoading = false;
    },err => {
      //alert("Invalid User Id or Password. Try again.");
      this.errorMessage = "Invalid User Id or Password. Try again."
      this.loadingService.showLoading = false;
    });
  }

  onClickLogin(){
    this.showLoginDialog = true;  
  }

  cancelLogin(){
    this.showLoginDialog = false;
  }

  onClickLogout(){
    this.loadingService.showLoading = true;
    var loginUser = this.storageService.get("LOGIN_USER_ID");
    var loginSession = this.storageService.get("LOGIN_SESSION_ID");
    this.dataService.logout(loginUser,loginSession).subscribe(res => {
      this.messageService.add({severity: 'success', summary: 'Logut Successfully'});
      this.loadingService.showLoading = false;
    },err => {
      alert("Invalid User Id or Password. Try again.");
      this.loadingService.showLoading = false;
    });
    this.storageService.remove("LOGIN_USER_ID");
    this.storageService.remove("LOGIN_SESSION_ID");
    this.eventManager.isUserLoggedIn = false;
  }
}


import { Inject } from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import { DataProviderService } from '../services/data-provider.service';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
import { UtilitiesService } from '../services/utilities.service';
import { ThemeService } from '../services/theme.service';
import { EventManagerService } from '../services/event-manager.service';
import { MessageService } from 'primeng/primeng';
import { LoadingService } from '../services/loading.service';
import { environment } from 'src/environments/environment';
import { HotelInfo } from '../data/model';
import { MarkdownService } from 'ngx-markdown';

export interface DialogData {
  name: string;
  street: string;
  city: string;
  state: string;
  country: string;
  latitude: string;
  longitude: string;
  phone: string;
  email: string;
}

@Component({
  templateUrl: '../material-dialog-html-css/hotel-info.html',
  styleUrls: ['../material-dialog-html-css/hotel-info.css']
})
export class HeaderInfoDialog {

  houseRules :string;
  constructor(
    public dialogRef: MatDialogRef<HeaderInfoDialog>,
    private markdownservice : MarkdownService,
    @Inject(MAT_DIALOG_DATA) public data: HotelInfo) {
      this.houseRules = this.markdownservice.compile(data.house_rules); 
    }

    getGMapsURL(){
      if(this.data.latitude == 0 && this.data.longitude == 0) return null;
      return 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBj5qjUlMZGz02PN6gcZdR9tyu8mIZgn-0&q='+this.data.latitude+','+this.data.longitude+'&zoom=20';
    }
}

