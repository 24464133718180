import { Component, OnInit, Injector } from '@angular/core';
import {ConnectionService} from 'ng-connection-service';
import { DataProviderService } from '../services/data-provider.service';
import { EventManagerService } from '../services/event-manager.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {
  status = 'ONLINE';
  isConnected = true;
  displayDialog = false;
  connectionService;
  constructor(private dataService: DataProviderService,
              private eventManager: EventManagerService,
              private injector: Injector) {
   }

  ngOnInit() {
    window.scroll(0, 0);
    this.connectionService = this.injector.get<ConnectionService>(ConnectionService);
    let temp = 1;
    const that = this;
    this.connectionService.monitor().subscribe(isConnected =>  {
      that.isConnected = isConnected.hasInternetAccess;
      if (isConnected.hasInternetAccess) {
        if (temp === 1) {
          that.dataService.routeToHomePage(that.dataService.hotelId);
          temp++;
        }} else {
          temp = 1;
        }});
}
onClickReturnToHome() {
  if (this.isConnected) {
    this.eventManager.viewChange({source: 'search-criteria-results'});
  } else {
    this.displayDialog = true;
  }
}
onClickCancelledOk() {
  this.displayDialog = false;
}
}
