import { Component, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { DataProviderService } from '../services/data-provider.service';
import { UtilitiesService } from '../services/utilities.service';
import { SearchCriteria, RoomCriteria } from '../data/model';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-search-criteria',
  templateUrl: './search-criteria.component.html',
  styleUrls: ['./search-criteria.component.css']
})
export class SearchCriteriaComponent implements OnInit, OnDestroy {
  todaysDate;
  minCheckinDate;
  maxCheckinDate;
  checkinDate;
  checkoutDate;
  roomNights = 0;
  displayDialog = false;
  showRemoveRoomButton = false;
  disabledDates = [];
  searchCriteria: SearchCriteria;
  checkinDates: Date[];
  activeDates = [];
  dialogRef;
  closeoutDates;
  selectedHotel;
  domainHotelsOptions = [];
  checkoutDateSubscription;
  kidsChildAgeOptions = [];
  kidsInfantsAgeOptions = [];
  promoCode;
  constructor(
    public dataService: DataProviderService,
    private eventManager: EventManagerService,
    public utilities: UtilitiesService,
    public dialog: MatDialog,
    private storageService: StorageService,
    private snackBar: MatSnackBar,
    private loadingService: LoadingService) {
  }

  ngOnInit() {
    this.unsubscribeEvents();
    this.initializeSearchCriteria();
    this.initializeDomainHotels();
    this.subscribeToCheckout();
    this.disableCloseoutDates();
  }

  initializeSearchCriteria() {
    this.checkinDates = new Array<Date>();
    const today = new Date();
    this.todaysDate  = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const tommorrowsDate = new Date(this.todaysDate);
    tommorrowsDate.setDate(tommorrowsDate.getDate() + 1);
    if (this.dataService.CheckIn != null && this.dataService.CheckOut != null) {
      if (this.validateCheckinDates(this.dataService.CheckIn, this.dataService.CheckOut)) {
        this.checkinDates.push(new Date(this.dataService.CheckIn));
        this.checkinDates.push(new Date(this.dataService.CheckOut));
      } else {
        this.checkinDates.push(new Date(this.todaysDate));
        this.checkinDates.push(new Date(tommorrowsDate));
      }
    } else {
      this.checkinDates.push(new Date(this.todaysDate));
      this.checkinDates.push(new Date(tommorrowsDate));
    }
    this.roomNights = this.utilities.dateDifference(this.checkinDates[0], this.checkinDates[1]);
    this.minCheckinDate = new Date(this.todaysDate);
    this.maxCheckinDate = new Date(this.todaysDate);
    this.maxCheckinDate.setFullYear(this.maxCheckinDate.getFullYear() + 2);
    this.kidsChildAgeOptions = this.setKidsChildAgeOptions();
    this.kidsInfantsAgeOptions = this.setKidsInfantAgeOptions();

    // Initializing Search Criteria
    this.searchCriteria = new SearchCriteria();
    if (this.dataService.RoomsCriteria) {
      this.searchCriteria.rooms = this.dataService.RoomsCriteria;
    } else if (this.dataService.nRooms && this.dataService.nAdults && (this.dataService.nChildrens || this.dataService.childAgeArray)) {
      this.searchCriteria.rooms = new Array<RoomCriteria>();
      let totalKids = 0;
      let totalKidsCount = 0;
      let childAgeArray;
      //MaxGuest
      var maxCount =  this.dataService.getMaxGuestsCount()
      if (this.dataService.nAdults / this.dataService.nRooms > maxCount ) {
        this.dataService.nAdults = this.dataService.nRooms * maxCount;
      }
      if (this.dataService.nAdults / this.dataService.nRooms < 1 ) {
        this.dataService.nAdults = maxCount;
      }
      let marginAdultCount = this.dataService.nAdults % this.dataService.nRooms;
      const tempAdultCount = this.dataService.nAdults - marginAdultCount;
      if (this.dataService.childAgeArray) {
        childAgeArray = this.dataService.childAgeArray.split(',');
        totalKidsCount = childAgeArray.length;
      } else if (this.dataService.nChildrens) {
        totalKidsCount = this.dataService.nChildrens;
      } else {
        totalKidsCount = 0;
      }
      if (totalKidsCount > this.dataService.nRooms * 2) {
        totalKidsCount = this.dataService.nRooms * 2;
      }
      for (let roomNum = 0; roomNum < this.dataService.nRooms; roomNum++) {
        const roomCriteria = new RoomCriteria();
        roomCriteria.KidsAgeArray = new Array<number>();
        roomCriteria.RoomNumber = roomNum + 1;
        if (marginAdultCount > 0) {
          roomCriteria.AdultsCount = tempAdultCount / this.dataService.nRooms + 1;
          marginAdultCount --;
        } else {
          roomCriteria.AdultsCount = tempAdultCount / this.dataService.nRooms;
        }

        if (totalKidsCount - totalKids === 1) {
          if (childAgeArray) {
            roomCriteria.KidsAgeArray = [childAgeArray[2 * roomNum]];
          } else {
            roomCriteria.KidsAgeArray = [0];
          }
          totalKids += totalKidsCount - totalKids;
        } else if (totalKidsCount - totalKids > 1) {
          if (childAgeArray) {
            roomCriteria.KidsAgeArray = [childAgeArray[2 * roomNum], childAgeArray[2 * roomNum + 1]];
          } else {
            roomCriteria.KidsAgeArray = [0, 0];
          }
          totalKids += 2;
        }
        roomCriteria.DisableAddAdultButton = false;
        roomCriteria.DisableRemoveAdultButton = false;
        roomCriteria.DisableAddKidButton = false;
        roomCriteria.DisableRemoveKidButton = false;
        this.searchCriteria.rooms.push(roomCriteria);
      }
    } else {
      this.searchCriteria.rooms = new Array<RoomCriteria>();
      const roomCriteria = new RoomCriteria();
      roomCriteria.RoomNumber = 1;
      roomCriteria.AdultsCount = 2;
      roomCriteria.DisableAddAdultButton = false;
      roomCriteria.DisableRemoveAdultButton = false;
      roomCriteria.KidsAgeArray = new Array<number>();
      roomCriteria.DisableAddKidButton = false;
      roomCriteria.DisableRemoveKidButton = false;
      this.searchCriteria.rooms.push(roomCriteria);
    }
    this.activeDates = this.utilities.getActiveDates(this.checkinDates[0].getMonth(), this.checkinDates[0].getFullYear());
    // Search criteria and Search results component are loaded in parallel. But we want to execute ngOnInit
    // of Search results as it containes listener for search criteria.

    this.promoCode = this.dataService.promoCode;
    
    const that = this;
    setTimeout(() => {
      that.onCheckAvailability(that.promoCode);
    }, 100);
  }

  initializeDomainHotels() {
    this.domainHotelsOptions = new Array<any>();
    if(this.dataService.domainHotels){
      for (const hotel of this.dataService.domainHotels) {
        if (hotel.hotel_id.toLowerCase() === this.dataService.hotelId.toLowerCase()) {
          this.selectedHotel = hotel.name;
        }
        this.domainHotelsOptions.push({ label: hotel.name,
                                        value: hotel.name,
                                        id: hotel.hotel_id});
      }
    }
  }

  subscribeToCheckout() {
    this.checkoutDateSubscription = this.eventManager.checkoutDateListener$.subscribe(checkout => {
      this.checkinDates[1] = checkout.date;
      this.dialogRef.close();
      this.onChangeCalendar();
    });
  }

  disableCloseoutDates() {
    // this.dataService.getHotelAvail(minDate,maxDate).subscribe(result => {
    //   this.closeoutDates = this.dataService.getCloseoutData(minDate,maxDate,result);
    //   this.setDisabledDates(minDate,maxDate,this.closeoutDates);
    // });
  }

  setDisabledDates(minDate, maxDate, closeoutDates) {
    for (const date = new Date(minDate); date.getTime() <= maxDate.getTime(); date.setDate(new Date(date).getDate() + 1)) {
      for (const data of closeoutDates) {
        if (data.date.getTime() === date.getTime()) {
          if (data.isClosed) {
            this.disabledDates.push(new Date(data.date));
          }
        }
      }
    }
  }

  setKidsChildAgeOptions() {
    const options = new Array<{}>();
    const childAgeLimit = this.dataService.getChildAgeLimit();
    for (let i = 5; i <= childAgeLimit; i++ ) {
      options.push({ label: i.toString(), value: i });
    }
    return options;
  }

  setKidsInfantAgeOptions() {
    const options = new Array<{}>();
    const childAgeLimit = this.dataService.getInfantAgeLimit();
    for (let i = 0; i <= childAgeLimit; i++ ) {
      options.push({ label: i.toString(), value: i });
    }
    return options;
  }

  addRoomCriteria() {
    this.showRemoveRoomButton = true;
    const roomCriteria = new RoomCriteria();
    roomCriteria.RoomNumber = this.searchCriteria.rooms[this.searchCriteria.rooms.length - 1].RoomNumber + 1;
    roomCriteria.AdultsCount = 2;
    roomCriteria.DisableAddAdultButton = false;
    roomCriteria.DisableRemoveAdultButton = false;
    roomCriteria.KidsAgeArray = new Array<number>();
    roomCriteria.DisableAddKidButton = false;
    roomCriteria.DisableRemoveKidButton = false;
    this.searchCriteria.rooms.push(roomCriteria);
  }

  removeRoomCriteria(i) {
    this.searchCriteria.rooms.splice(i, 1);
    }

    DisableAddInfantsButton = false;
    DisableRemoveInfantsButton = false;
    infantCount = 0;
  onChangeSearchCriteria(i, source) {
    if (source === 'adultCountIncrease' || source === 'adultCountDecrease') {
      if (source === 'adultCountIncrease' && this.searchCriteria.rooms[i].AdultsCount < 4) {
        this.searchCriteria.rooms[i].AdultsCount += 1;
      } else if (source === 'adultCountDecrease' && this.searchCriteria.rooms[i].AdultsCount > 0) {
        this.searchCriteria.rooms[i].AdultsCount -= 1;
      }
      if (this.searchCriteria.rooms[i].AdultsCount === 4) {
        this.searchCriteria.rooms[i].DisableAddAdultButton = true;
      } else {
        this.searchCriteria.rooms[i].DisableAddAdultButton = false;
      }
      if (this.searchCriteria.rooms[i].AdultsCount === 0) {
        this.searchCriteria.rooms[i].DisableRemoveAdultButton = true;
      } else {
        this.searchCriteria.rooms[i].DisableRemoveAdultButton = false;
      }
      //rateplan.GuestCount = this.searchCriteria.rooms[i].AdultsCount;
    }
    if (source === 'kidsCountIncrease' || source === 'kidsCountDecrease') {
      if (source === 'kidsCountIncrease' && this.searchCriteria.rooms[i].KidsAgeArray.length < 2) {
        this.searchCriteria.rooms[i].KidsAgeArray.push(0);
      } else if (source === 'kidsCountDecrease' && this.searchCriteria.rooms[i].KidsAgeArray.length > 0) {
        this.searchCriteria.rooms[i].KidsAgeArray.pop();
      }
      if (this.searchCriteria.rooms[i].KidsAgeArray.length === 2) {
        this.searchCriteria.rooms[i].DisableAddKidButton = true;
      } else {
        this.searchCriteria.rooms[i].DisableAddKidButton = false;
      }
      if (this.searchCriteria.rooms[i].KidsAgeArray.length === 0) {
        this.searchCriteria.rooms[i].DisableRemoveKidButton = true;
      } else {
        this.searchCriteria.rooms[i].DisableRemoveKidButton = false;
      }
      //rateplan.ChildCount = this.searchCriteria.rooms[i].AdultsCount;
    }
    if (source === 'infantsCountIncrease' || source === 'infantsCountDecrease') {
      if (source === 'infantsCountIncrease' && this.infantCount < 2) {
        this.infantCount  = this.infantCount + 1;
      } else if (source === 'infantsCountDecrease' && this.infantCount > 0) {
        this.infantCount  -= 1;
      }
      if (this.infantCount === 2) {
        this.DisableAddInfantsButton = true;
      } else {
        this.DisableAddInfantsButton = false;
      }
      if (this.infantCount === 0) {
        this.DisableRemoveInfantsButton = true;
      } else {
        this.DisableRemoveInfantsButton = false;
      }
    }
  }

  // onChangeKidsDropdown(room, index, event) {
  //   room.KidsAgeArray[index] = event.value;
  // }

  setTotalKidsAdultsCount() {
    let adultsCount = 0;
    let kidsCount = 0;
    for (const room of this.searchCriteria.rooms) {
      adultsCount += room.AdultsCount;
      kidsCount += room.KidsAgeArray.length;
    }
    this.searchCriteria.adultsCount = adultsCount;
    this.searchCriteria.kidsCount = kidsCount;
  }

  onCheckAvailability(promoCode=null) {
    if (this.checkinDates[0] == null) {
      this.openSnackBar('Please enter checkin date', 'Ok');
      this.roomNights = 0;
    } else {
      if (this.checkinDates) {
        this.roomNights = this.utilities.dateDifference(this.checkinDates[0], this.checkinDates[1]);
      }
      this.setSearchCriteria();
      this.eventManager.viewChange({source: 'search-criteria-results'});
      const that = this;
      setTimeout(() => {
        that.eventManager.changeSearchCriteria(this.searchCriteria);
      }, 1000);
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  setSearchCriteria() {
    this.searchCriteria.stayDateStart = this.utilities.transformDate(new Date(this.checkinDates[0]), 'yyyy-MM-dd');
    this.searchCriteria.stayDateEnd = this.utilities.transformDate(new Date(new Date(this.checkinDates[1])), 'yyyy-MM-dd');
    this.searchCriteria.checkinDate = this.utilities.removeTime(new Date(this.checkinDates[0]));
    this.searchCriteria.checkoutDate = this.utilities.removeTime(new Date(this.checkinDates[1]));
    this.searchCriteria.roomNights = this.roomNights;
    this.dataService.RoomsCriteria = this.searchCriteria.rooms;
    this.searchCriteria.promoCode = this.promoCode;
    this.setTotalKidsAdultsCount();
  }

  onChangeCalendar() {
    if (this.checkinDates[0] != null && this.checkinDates[1] != null) {
      this.roomNights = this.utilities.dateDifference(this.checkinDates[0], this.checkinDates[1]);
      this.onCheckAvailability();
    }
  }

  validateCheckinDates(checkin, checkout) {
    const checkinDate = this.utilities.removeTime(new Date(checkin));
    const checkoutDate = this.utilities.removeTime(new Date(checkout));
    const todaysDate = new Date();
    if (this.utilities.dateDifference(todaysDate, checkinDate) < 0) {// check if checkin date is todays date or past date
      return false;
    } else {
      if (this.utilities.dateDifference(checkinDate, checkoutDate) >= 0) {// Check if checkin date is less than checkout date
      return true;
      } else {// Check if checkout date is less than checkin date
        return false;
      }
    }
  }

  async updateActiveDates(event) {
    this.activeDates = this.utilities.getActiveDates(event.month, event.year);
    // this.closeoutDates = await this.dataService.getCloseoutDates(new Date(event.year,event.month,1));
    // this.cdr.detectChanges();
  }


  openDialog(event): void {
    const checkoutDialogWidth = 200;
    const offset = 20; // This is the offset at the right of dialog
    let leftPosition = 0;
    if (this.activeDates.indexOf(event.target.textContent.trim()) >= 0 &&
        ((JSON.stringify(event.target.outerHTML).indexOf('available') >= 0 ||
        JSON.stringify(event.target.outerHTML).indexOf('active') >= 0))) {
        // if(this.checkinDates[1] == null){
        // Check if window width is less than 992px, if yes then adjust the width accordingly
      if (window.innerWidth - event.pageX < checkoutDialogWidth + offset) {
        leftPosition = event.pageX - (checkoutDialogWidth + offset - (window.innerWidth - event.pageX));
      } else {
        leftPosition = event.pageX;
      }
      const dialogDates = this.utilities.getDialogDates(this.checkinDates[0], this.disabledDates);
      this.dialogRef = this.dialog.open(CheckOutDropdownComponent, {
        width: checkoutDialogWidth + 'px',
        maxHeight: '300px',
        data: dialogDates,
        position: {
          left: leftPosition + 'px',
          top: event.y + 'px'
        }
      });
      const that = this;
      this.dialogRef.afterClosed().subscribe(
        result => {
        if (that.checkinDates[1] == null) {
          that.checkinDates = new Array<Date>();
        }
      });
    }
    this.onChangeCalendar();
  }


  getCloseoutStatus(date) {
    for (const date1 of this.closeoutDates) {
      if (date1.date.getDate() === date.day &&
        date1.date.getMonth() === date.month &&
        date1.date.getFullYear() === date.year) {
        return date1.isClosed;
      }
    }
    return false;
  }

  onHotelChange(event) {
    this.loadingService.showLoading = true;
    this.eventManager.viewChange({source: 'switch-hotel'});
    this.storageService.clearCache();
    const hid = this.getHotelId(event.value);
    this.dataService.routeToHomePage(hid);
  }

  getHotelId(hotelName) {
    for (const hotel of this.dataService.domainHotels) {
      if (hotel.name === hotelName) {
        return hotel.hotel_id;
      }
    }
    return '';
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.unsubscribeEvents();
  }

  unsubscribeEvents() {
    if (this.checkoutDateSubscription) {
      this.checkoutDateSubscription.unsubscribe();
    }
  }
}


import { Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { StorageService } from '../services/storage.service';
import { EventManagerService } from '../services/event-manager.service';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: '../material-dialog-html-css/dialog-overview-example-dialog.html',
})
export class CheckOutDropdownComponent {
  constructor(
    public dialogRef: MatDialogRef<CheckOutDropdownComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventManager: EventManagerService) {}

    onClickCheckout(date) {
      if (!date.disabled) {
        this.eventManager.changeCheckoutDate(date);
      }
    }

    onNoClick(): void {
      this.dialogRef.close();
    }
}
