import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DataProviderService } from './data-provider.service';

@Injectable({
  providedIn: 'root'
})
export class EventManagerService {

  constructor(private dataService: DataProviderService) { }
  private viewSource = new Subject<any>();
  // Observable string streams
  viewChangeListener$ = this.viewSource.asObservable();

  private searchCriteriaSource = new Subject<any>();
  searchCriteriaListener$ = this.searchCriteriaSource.asObservable();

  private searchResultSource = new Subject<any>();
  searchResultListener$ = this.searchResultSource.asObservable();

  private checkoutDateSource = new Subject<any>();
  checkoutDateListener$ = this.checkoutDateSource.asObservable();

  private dialogListenerSource = new Subject<any>();
  dialogListener$ = this.dialogListenerSource.asObservable();

  isUserLoggedIn = false;

  viewChange(viewData) {
      this.viewSource.next(viewData);
  }

  changeSearchCriteria(criteria) {
    this.dataService.CheckIn = criteria.checkinDate;
    this.dataService.CheckOut = criteria.checkoutDate;
    this.dataService.TotalAdultsCount = criteria.adultsCount;
    this.dataService.TotalKidsCount = criteria.kidsCount;
    this.searchCriteriaSource.next(criteria);
  }

  scrollToResults() {
    this.searchResultSource.next();
  }

  changeCheckoutDate(checkoutDate) {
    this.checkoutDateSource.next(checkoutDate);
  }

  displayDialog(dialogmsg) {
    this.dialogListenerSource.next(dialogmsg);
  }

}
