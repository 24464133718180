export interface Theme {
    themename: string;
    properties: any;
}

export const themes: Theme[] = [
    {
        themename: "Yellow",
        properties: {
            "--theme-light": "#E9A83A",
            "--theme-dark": "#321800",
            "--t2-theme-light": "#E9A83A"
        }
    },
    {
        themename: "Blue",
        properties: {
            "--theme-light": "#8181FF",
            "--theme-dark": "#2D2D99",
            "--t2-theme-light": "#8181FF"
        }
    },
    {
        themename: "Cherry Red",
        properties: {
            "--theme-light": "#E66489",
            "--theme-dark": "#300813",
            "--t2-theme-light": "#E66489"
        }
    },
    {
        themename: "Purple",
        properties: {
            "--theme-light": "#8760D7",
            "--theme-dark": "#160B2D",
            "--t2-theme-light": "#8760D7"
        }
    },
    {
        themename: "Green",
        properties: {
            "--theme-light": "#5CDB94",
            "--theme-dark": "#0E3E23",
            "--t2-theme-light": "#5CDB94"
        }
    },
    {
        themename: "Clarks Red",
        properties: {
            "--theme-light": "#D4464B",
            "--theme-dark": "#461012",
            "--t2-theme-light": "#D4464B"
        }
    },
    {
        themename: "Cadet Blue",
        properties: {
            "--theme-light": "#84B4B6",
            "--theme-dark": "#1D3131",
            "--t2-theme-light": "#84B4B6"
        }
    },
    
]