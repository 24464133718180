import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventManagerService } from '../services/event-manager.service';
import { DataProviderService } from '../services/data-provider.service';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() hotelRules: string;

  currentDate: Date;
  constructor(
    private dataService : DataProviderService,
    private markdownService : MarkdownService,
  ) { }

  ngOnInit() {
    this.currentDate = new Date();
    if (!this.hotelRules){
      this.dataService.getHotelRules().subscribe(res => {
        this.hotelRules = res;
      });
    }
  }

  getFooterDialogSize() {
    if (window.innerWidth < 575) {
      return {
        width: window.innerWidth * 0.8 + 'px',
        'min-width': '200px',
      };
    } else {
      return {
        width: window.innerWidth * 0.5 + 'px',
        'min-width': '200px',
      };
    }
  }

  displayHouseRules = false;
  houseRulesData;
  onHotelRulesClick(){
    // this.displayDialogHeader = "HOTEL RULES & RESTRICITONS";
    if (!this.hotelRules){
      this.dataService.getHotelRules().subscribe(res => {
        this.hotelRules = res;
      });
    }
    this.houseRulesData = this.markdownService.compile(this.hotelRules);
    this.displayHouseRules = true;
  }


  displayPrivacyPolicy = false;
  privacyPolicy: string = null;
  onPrivacyPolicyClick(){
    if (this.privacyPolicy === null){
      this.dataService.getPrivacyPolicy().subscribe(res => {
        this.privacyPolicy = this.markdownService.compile(res);
      });
    }
    this.displayPrivacyPolicy = true;
  }
}
