import { Component, OnInit, Input, ChangeDetectorRef, HostListener } from '@angular/core';
import { DataProviderService, bookingData, customer } from '../services/data-provider.service';
import { StorageService } from '../services/storage.service';
import { UtilitiesService } from '../services/utilities.service';
import { BookingVoucher, VoucherRoom } from '../data/model';
import { LoadingService } from '../services/loading.service';
import * as jQuery from 'jquery';
import { SearchResultsService } from '../services/search-results.service';
import { EventManagerService } from '../services/event-manager.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/primeng';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-booking-voucher',
  templateUrl: './booking-voucher.component.html',
  styleUrls: ['./booking-voucher.component.css']
})
export class BookingVoucherComponent implements OnInit {
  reservationCart;
  guestDetails;
  hotelDetails;
  submitBooking;
  transactionDetails;
  bookingConfirmation;
  hotelLocation;
  hotelLogo;
  hotelBg;
  bookingVoucher;
  displayBookingCancelledDialog = false;
  displayBookingVoucher = false;
  printVoucherDialog = false;
  cancellationPolicyText = '';
  hotelEmailId;
  mealPlan;
  isCancelBookingClicked = false;
  @Input() bookingId;
  @Input() customerEmailId;
  isBookingModifiable = false;
  constructor(private dataProvider: DataProviderService,
              private eventManager: EventManagerService,
              private storageService: StorageService,
              public utilities: UtilitiesService,
              public loadingService: LoadingService,
              private cdr: ChangeDetectorRef,
              public srService: SearchResultsService,
              private messageService: MessageService,
              private router: Router) { }

  ngOnInit() {
    this.hotelDetails = this.storageService.get(StorageService.hotelDetails);
    this.getBookingNew();
  }

  getBookingNew() {
    this.bookingVoucher = new BookingVoucher();
    const params = {
      hotelId: this.dataProvider.hotelId,
      email: this.customerEmailId,
      bookingId: this.bookingId,
      partnercode: this.dataProvider.partnerCode
    };
    this.loadingService.showLoading = true;
    this.dataProvider.checkBooking(params).subscribe(result => {
      this.loadingService.showLoading = false;
      if (result.status === 'Failure') {
        let problems = '';
        for (const problem of result.problems) {
          problems += problem.detail + '\n';
        }
        console.log('Issue while submiting inputs in MANAGE BOOKING - ' + problems);
        this.eventManager.displayDialog({
          header: 'Error',
          detail: 'Invalid Booking or Email Id'
        });
        if(result.reservation.payment_type == "PostPaid" || result.reservation.payment_type == "Pending")
        this.bookingVoucher.PaymentType = "Pay@Hotel";
        else this.bookingVoucher.PaymentType = "Paid";
        this.eventManager.viewChange({source: 'manage-booking'});
      } else {
        this.setBookingVoucher(result);
        this.getCancellationPolicy();
        this.displayBookingVoucher = true;
      }
      this.cdr.detectChanges();
    });
  }

  setBookingVoucher(result) {
    if (result.reservation.status === 'Booked') {
      this.bookingVoucher.status = 'Confirmed';
    } else {
      this.bookingVoucher.status =  result.reservation.status;
    }
    this.bookingVoucher.HotelID = result.reservation.hotel_id;
    this.bookingVoucher.BookingID = result.reservation.reservation_id;
    this.bookingVoucher.CheckinDate = new Date(result.reservation.checkin_date);
    this.bookingVoucher.CheckoutDate = new Date(result.reservation.checkout_date);
    this.bookingVoucher.Customer = result.reservation.customer;
    this.bookingVoucher.Hotel = result.reservation.hotel;
    this.bookingVoucher.Rooms = this.getRooms(result);
    for (const item of result.reservation.receipt.line_items) {
      if (item.type === 'rate') {
        this.bookingVoucher.AmountExcludingTax += item.price.amount;
      }
      if (item.type === 'tax') {
        this.bookingVoucher.TotalTax += item.price.amount;
      }
    }
    if (this.bookingVoucher.Hotel.latitude > 0 || this.bookingVoucher.Hotel.longitude > 0) {
      this.hotelLocation = 'http://maps.google.com/?daddr=' +
                            this.bookingVoucher.Hotel.latitude + ',' + this.bookingVoucher.Hotel.longitude;
    }
    this.hotelLogo = environment.images_url + result.reservation.hotel_id.toLowerCase() + '/logo.png';
    this.hotelBg = environment.images_url + result.reservation.hotel_id.toLowerCase() + '/bg.png';
    this.isBookingModifiable =( this.dateDifference(new Date(), this.bookingVoucher.CheckinDate) > 0 && (this.bookingVoucher.status == "Confirmed" || this.bookingVoucher.status == "Modified"));
    this.setHotelEmailId();
  }

  getRooms(result) {
    const roomArray = new Array<VoucherRoom>();
    for (const room of result.reservation.rooms) {
      const roomVoucher = new VoucherRoom();
      roomVoucher.RoomName = room.roomdetails.room_name;
      roomVoucher.RoomDescription = this.setRoomDescription(room.roomdetails);
      roomVoucher.RatePlanName = room.roomdetails.rate_plan_name;
      if(room.roomdetails.final_price_at_booking)
      roomVoucher.Amount = room.roomdetails.final_price_at_booking.amount;
      else roomVoucher.Amount = 0
      roomVoucher.AdultsCount = room.party.adults;
      if (room.party.childrenCount && room.party.childrenCount > 0) {
        roomVoucher.KidsCount = room.party.childrenCount;
      } else {
        roomVoucher.KidsCount = 0;
      }
      roomVoucher.MealPlan = this.getMealPlan(room.roomdetails);
      roomArray.push(roomVoucher);
    }
    return roomArray;
  }

  setRoomDescription(roomDetails) {
    const roomId = roomDetails.room_id;
    for (const room in this.hotelDetails.room_types ) {
      if(this.hotelDetails.room_types[room].room_id === roomId) {
        return this.hotelDetails.room_types[room].desc;
      }
    }
    return '';
  }
  getCancellationPolicy() {
    if (this.cancellationPolicyText === '') {
      this.loadingService.showLoading = true;
      const cancellationparams = {
        partnercode: this.dataProvider.partnerCode,
        bookingid: this.bookingVoucher.BookingID
      };
      this.dataProvider.getCancellationFees(cancellationparams).subscribe(result => {
        this.loadingService.showLoading = false;
        this.cancellationPolicyText = this.cleanCancellationPolicyText(result.text);
        this.cdr.detectChanges();
      });
    }
  }

  cleanCancellationPolicyText(text) {
    if (text === 'Non-Refundable') {
      return 'No refund will be given after cancelling this booking.';
    } else {
      return text;
    }
  }
  onClickConfirmCancellation() {
    this.loadingService.showLoading = true;
    this.dataProvider.cancelBooking(this.bookingVoucher.HotelID, this.bookingVoucher.BookingID).subscribe(result => {
    this.loadingService.showLoading = false;
    if (result.status === 'Success') {
      this.getBookingNew();
      this.cancellationPolicyText = '';
      // this.displayBookingCancelledDialog = true;
    } else {
      this.eventManager.displayDialog({
        header: 'Error',
        detail: 'Booking cancellation failed.. Please retry..!'
      });
    }
    });
  }

  onClickCancelCancellation() {
    this.isCancelBookingClicked = false;
    this.cdr.detectChanges();
  }

  setHotelEmailId() {
    const hotelDetails = this.storageService.get(StorageService.hotelDetails);
    if (hotelDetails && hotelDetails.email && hotelDetails.email !== null && hotelDetails.email !== '') {
      this.hotelEmailId = hotelDetails.email.split(',')[0];
    }
  }

  getMealPlan(roomDetails) {
    const hotelDetails = this.storageService.get(StorageService.hotelDetails);
    if (hotelDetails) {
      for (const room in hotelDetails.room_types) {
        if (hotelDetails.room_types[room].room_id === roomDetails.room_id &&
          hotelDetails.room_types[room].rate_plan_id === roomDetails.rate_plan_id) {
            return this.srService.getMealPlan(hotelDetails.room_types[room].meal_plan_code);
          }
      }
    }
    return;
  }

  openPrintDialog() {
    this.printVoucherDialog = true;
    this.cdr.detectChanges();
    const printContents = document.getElementById('bookingVoucherPrint').innerHTML;
    const headContent = document.getElementsByTagName('head')[0].innerHTML;
    const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`<html><head>
      ${headContent}
      <style>@media print {
        body {-webkit-print-color-adjust: exact;}
        }</style
        </head><body onload="window.print();window.close()"><div>${printContents}</div></body>
      </html>`);
    this.printVoucherDialog = false;
    this.cdr.detectChanges();
    popupWin.document.close();
  }

  @HostListener('window:resize') setHeight() {
    let windowWidth = window.innerWidth;
    if (windowWidth > 540) {
      windowWidth = windowWidth * 0.8;
    } else {
      windowWidth = windowWidth * 0.9;
    }
    jQuery('#bookingVoucherPrint').css('width', windowWidth);
  }

  onClickHomeButton(){
    //location.href = this.websiteurl;
    let initialparams = this.storageService.get(StorageService.initialparams);
    let currentparams = this.storageService.get(StorageService.params);
    
    if(currentparams && currentparams.res){
      this.router.navigate([''],
      {queryParams:initialparams});
    }
    else{
      this.eventManager.viewChange({source:'search-criteria-results'});
    }
  }

  showModifyButton = false;
  showModifyDialog = false;
  modifiedStartDate = new Date();
  minDate = new Date();
  errorMessage = null;
  modifiedEndDate = new Date();
  nights = 1;
  updateStayDate = false;
  updateGuestName = false;
  updateEmail = false;
  firstName;
  lastName;
  email;
  mobile;
  showModifyBookingDialog(){
    this.errorMessage = null;
    this.showModifyDialog=true;
    this.modifiedStartDate = new Date(this.bookingVoucher.CheckinDate);
    this.modifiedEndDate = new Date(this.bookingVoucher.CheckoutDate);
    this.nights = this.dateDifference(this.modifiedStartDate,this.modifiedEndDate);
    this.firstName = null;
    this.lastName = null;
    this.email = null;
    this.mobile = null;
    this.updateStayDate = false;
    this.updateGuestName = false;
    this.updateEmail = false;
    this.showModifyButton = false;
  }

  dateDifference(startDate, endDate) {
    let oneDay = 1000 * 60 * 60 * 24;
    return Math.round((endDate.getTime() - startDate.getTime()) / oneDay);
  }

  modifiedBookingData;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  checkModification(){
    //var endDate = new Date(this.modifiedStartDate);
    //endDate.setDate(this.modifiedStartDate.getDate()+3);
    this.errorMessage = null;
    this.loadingService.showLoading = true;
    let bdata = new bookingData();
    bdata.booking_id = this.bookingVoucher.BookingID;
    if(this.updateStayDate){
      bdata.checkin_date = this.utilities.transformDate(this.modifiedStartDate, 'yyyy-MM-dd');
      bdata.checkout_date = this.utilities.transformDate(this.modifiedEndDate, 'yyyy-MM-dd');
    }
    if(this.updateGuestName){
      bdata.customer = new customer();
      if(this.firstName) bdata.customer.first_name = this.firstName;
      if(this.lastName) bdata.customer.last_name = this.lastName;
    }
    if(this.updateEmail){
      if(!bdata.customer) bdata.customer = new customer();
      if(this.email) bdata.customer.email = this.email;
      if(this.mobile) bdata.customer.mobile = this.mobile; 
    }
    this.dataProvider.checkBookingModification(this.dataProvider.partnerCode,bdata).subscribe(
      result => {
        if(result.reservation != null && result.reservation.status && result.reservation.status == "DoModification"){
          this.showModifyButton = true
          this.modifiedBookingData = bdata;
        } else if(result.problems[0]["explanation"] !=null)
          this.errorMessage = result.problems[0]["explanation"];
        this.loadingService.showLoading = false;
      }
    );
  }

  updateCheckoutDate(){
    this.modifiedEndDate = new Date(this.modifiedStartDate);
    this.modifiedEndDate.setDate(this.modifiedEndDate.getDate()+this.nights);
  }

  modifyBooking(){
    this.errorMessage = null;
    this.loadingService.showLoading = true;
    this.dataProvider.modifyBooking(this.dataProvider.partnerCode,this.modifiedBookingData).subscribe(
      result => {
        if(result.reservation != null && result.reservation.status && result.reservation.status == "Modify"){
          this.showModifyDialog = false;
          this.getBookingNew();
          this.messageService.add({severity: 'success', summary: 'Booking modified successfully.',detail: 'Booking modified successfully.'});
          this.showModifyButton = false;
        } else if(result.problems[0]["explanation"] !=null){
          this.showModifyButton = false;
          this.errorMessage = result.problems[0]["explanation"];
        }
        this.loadingService.showLoading = false;
      }
    );
  }

  cancelModifyDialog(){
    this.showModifyDialog = false;
  }
}
