export const environment = {
  env:  'prod',
  production: true,
  images_url: 'https://secure.maximojo.com/images/',
  serviceUrl: 'https://secure.maximojo.com/mantrasdirect',
  authorization: 'Basic bWFudHJhc2RpcmVjdEBtYXhpbW9qby5jb206NTQxNDY5NTQ2NTQ2OTc3MjE0ODc2NTE0Njc4NDIxNzE0NDc5NjUxNDg2OTUwMjA3',
  partnerCode: 'EXT-BE',
  payment_gateway_url: 'https://bookingengine.maximojo.com/airpaygateway/sendtoairpay.aspx',
  payment_gatewayPayU_url: 'https://bookingengine.maximojo.com/airpaygateway/sendtoPayU.aspx',
  payment_callback_url: 'https://bookingengine.maximojo.com/',
  widget: false
};
